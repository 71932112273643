@media all and (max-width: 1023px) {
  .test,
  .slider,
  .test-grown-slider,
  .test-grown-in,
  .video,
  .slider-context .swiper-container.slider__content-context{
    position: relative;
  }

  .help__button,
  .logo svg {
    color: #000 !important;
    fill:#000 !important;
  }
  .help__button{
    background: rgba(0,0,0,.1);
  }
  //layout
  .content-link__next{
    display: none;
  }
  .url-pager{
    display: none;
  }
  //animated
  .section-index{
    .animation-content{
      display: block;
    }
    &.preload{
      .container{
        padding-bottom: 50px;
      }
    }
    .help__button,
    .logo svg,
    .info{
      color: #fff !important;
      fill:#fff !important;
    }
  }
  .animation-content{
    display: none;
  }
  .saw{
    height: 50vw;
    top: inherit;
    left: 0;
    bottom: 104px;
    position: absolute;
    &:before{
      background-image: linear-gradient(to bottom right, #655c9f 50.5%, transparent 50.5%);
      background-position: 42px 0, 10px 0;
      background-size: 42px 18px, 20px 20px;
    }
    &:after{
      background-image: linear-gradient(to bottom right,transparent  50.5%, #655c9f 50.5%);
      background-position: 42px 0, 10px 0;
      background-size: 42px 18px, 20px 20px;
    }
    &__black{
      &:before{
        background-image: linear-gradient(to bottom right, #000 50.5%, transparent 50.5%);
        background-position: 42px 0, 10px 0;
        background-size: 42px 18px, 20px 20px;
      }
      &:after{
        background-image: linear-gradient(to bottom right,transparent  50.5%, #000 50.5%);
        background-position: 42px 0, 10px 0;
        background-size: 42px 18px, 20px 20px;
      }
    }
  }

  //end animated
  .link-next{
    display: block;
    font:800 18px/22px 'Proxima Nova';
    color: #fff;
    letter-spacing: -0.2px;
    position: relative;
    text-decoration: none;
    margin-top: 61px;
    text-align: center;
    &__icon{
      width:0;
      display: block;
      border-top:5px solid transparent;
      border-bottom:5px solid transparent;
      border-left:10px solid #fff;
      margin:30px auto 0;
    }
  }
  .container{
    padding:20px;
  }
  .wrapper{
    display: block;
    min-height:100%;
    height: initial;
    position: relative;
    padding:0;
  }
  .section-index{
    padding:0;
  }
  section{
    padding: 0 0 140px;
  }
  .content{
    display: block;
    min-height:100%;
    margin:0;
    &__left{
      min-height:100%;
      padding: 90px 174px 38px 90px;
      .count{
        display: none;
        &__rotate{
          display: flex;
        }
      }
      &-controls{
        display: none;
      }
      &-box{
        width:100%;
        padding:0;
      }
      &-help {
        padding: 90px 0 0 100px;
      }
    }
    &__right{
      min-height:100%;
      &-help {
        width: 100%;
        padding: 0 0 82px 100px;
      }
    }
    &__head{
      .count{
        display: flex;
        &__black{
          margin:0 -84px auto auto;
          position: absolute;
          right: -10%;
          top: 0;
        }
      }
    }
  }
  .help-page {
    width: 315px;
    &__left {
      margin: 0;
      padding-top: 0;
      padding-right: 0;
      padding-bottom: 40px;
      border-bottom: 1px solid #e8e8e8;
      border-right: none;
    }
    &__right {
      padding-top: 30px;
    }
  }
  h1{
    font:800 40px/48px 'Proxima Nova';
    margin-top: 12px;
    &:after{
      width:108px;
      height:5px;
      margin-left: 15px;
    }
  }
  h3{
    font-size:40px;
    line-height: 48px;
    margin-bottom: 25px;
  }
  .description-text{
    margin: 0 0 25px 15px;
  }
  p{
    font-size: 18px;
    line-height: 26px;
  }
  section{
    display: block;
  }
  .button{
    min-width: 240px;
    margin-left: 20px;
    box-shadow: 0 -6px 34px 0 rgba(255, 255, 255, 0.5);
    background: #fff;
    &__text-hover{
      top: 50%;
    }
    &-box{
      margin:46px 0 0 -20px;
      justify-content: flex-start;
    }
  }
  .main-menu{
    &__toggle{
      left:0;
      top:0;
      &-box{
        &:hover{
          .main-menu__toggle{
            left:0;
            transform: none;
          }
        }
      }
      &-shadow{
        //display: none;
      }
    }
    &--active{
      position: fixed;
      left:0;
      top: 0;
      .main-menu__toggle{
        &-box{
          left: 0;
          top:0;
        }
        &-shadow{
          opacity: 0;
        }
      }
    }
    &__top{
      display: block;
      position: absolute;
      top:90px;
      right:90px;
      z-index: 3;
    }
    &__buttom{
      display: block;
      margin:auto 0 0 90px;
      position: relative;
      z-index: 3;
    }
    &__content{
      margin-bottom: 45px;
    }
    &__dropdown{
      height: 100%;
      padding:73px 0 40px;
      width:100%;
      position: fixed;
      overflow-y: scroll;
      z-index: 3;
      left:0;
      top:0;
      display: flex;
      justify-content:flex-start;
    }
    &__title,
    &__item,
    &__index{
      padding-left: 101px;
    }
    &__item{
      &--active{
        &:before{
          left: 75px;
        }
      }
    }
  }
  .preload{
    .main-menu__toggle-box{
      left: -2px;
      top: -2px;
    }
  }
  .lang-switch{
    visibility: hidden;
    opacity:0;
    right:24px;
    z-index: 4;
    &--visible{
      visibility: visible;
      opacity: 1;
    }
    &__item{
      color: #000 !important;
      &--active{
        &:after{
          border-top-color: #000 !important;
        }
      }
    }
  }
  .info{
    //bottom:13px;
    right: 55px;
    //margin-bottom:0;
    color: #000 !important;
    z-index: 4;
    &:after{
      display: none;
    }
    &--visible{
      visibility: visible;
      opacity: 1;
    }
    &--materials{
      right:160px;
    }
  }
  .social{
    margin-left: 101px;
  }
  //index
  .videoloop{
    position: fixed;
  }
  .animation-content{
    position: fixed;
    &__item{
      position: fixed;
    }
    &__count{
      font-size:80px;
    }
    &__box{
      flex-direction: column;
      align-items: flex-start;
      width: 85%;
      padding-bottom: 30px;
    }
    &__progress{
      &-box{
        margin:0;
        width:100%;
      }
    }
  }
  .content-border{
    padding:90px;
    height: 100%;
    &__middle{
      flex-direction: column;
      margin-top: 40px;
      >*{
        width:408px;
        margin-top: 75px;
      }
    }
    &__bottom{
      flex-direction: column-reverse;
      align-items: flex-start;
      >*{
        width:100%;
        margin-top: 75px;
      }
    }
    &__top{
      .content-border{
        &__right{
          margin-top:auto;
          width:auto;
        }
      }
    }
  }
  .count{
    &__item{
      border-top: 18px solid transparent;
      border-left: 21px solid #fff;
    }
    &__black{
      width: 84px;
      .count__item{
        border-left: 21px solid #000;
      }
    }
  }
  .section-index{
    .count{
      width: 84px;
    }
  }
  .videoloop{
    video{
      height: 1038px ;
      display: block;
      width: initial;
    }
  }
  //advice
  .advice{
    overflow: inherit;
    max-height: 100%;
    height: 100%;
    flex-direction: column;
    &__title{
      font-size: 28px;
      line-height: 34px;
    }
    &__item{
      width: 100%;
      padding: 20px 50px 20px 48px;
      min-height:350px;
      &:nth-child(even){
        background: #fff;
        color: #000;
        .advice{
          &__link{
            color: #000;
          }
          &__icon{
            path{
              fill:#000;
            }
          }
        }
      }
      &:nth-child(odd){
        .advice{
          &__link{
            color: #fff;
          }
          &__icon{
            path{
              fill:#fff;
            }
          }
        }
      }
      &:nth-child(4n+2){
        background: #fff;
        color: #000;
        .advice__icon{
          path{
            fill: #000;
          }
        }
        .advice__link{
          color: #000;
          &:after{
            background: rgba(0,0,0,1);
          }
        }
      }
      &:nth-child(4n+3){
        background: #000;
        color: #fff;
        .advice__icon{
          path{
            fill: #fff;
          }
        }
        .advice__link{
          color: #fff;
          &:after{
            background: rgba(255,255,255,1);
          }
        }
      }
    }
    &__text{
      margin-top: 19px;
      font-size: 17px;
      line-height: 21px;
    }
    &__right{
      width: 362px;
      margin: 20px 0;
    }
    &__link{
      margin-top: 46px;
      visibility: visible;
      opacity: 1;
    }
    &__icon{
      display: block;
      width: 175px;
      height: 175px;
      margin-left: 14px;
    }
    &__white{
      background: #000;
      color: #fff;
      .advice{
        &__link{
          color: #fff;
        }
      }
    }
  }
  //advice in
  .advice-in{
    height:100%;
    &__title{
      font-size: 28px;
      line-height: 34px;
    }
    &__social-item{
      width: 100%;
      height: 20px;
    }
    &__avatar{
      width: 175px;
      height: 175px;
      margin-left: 15px;
    }
    &__head{
      padding: 17px 36px 24px 48px;
      &-content{
        margin:66px 0 57px 76px;
      }
    }
    &__content{
      margin:0;
      padding: 84px 90px 50px 90px;
      width: 100%;
      max-width: 100%;
      p{
        font-size: 18px;
        line-height: 26px;
      }
    }
  }
  //kids
  .slider-bottom-overflow{
    display: none;
  }
  .slider__content .swiper-wrapper{
    height: 100% !important;
    display: block;
  }
  .slider{
    overflow: inherit;
    height: 100%;
    &:after{
      display: none;
    }
    .swiper-container{
      height: 100% !important;
      margin-top: -38px;
      min-height: 100%;
    }
    &__text{
      display: none;
    }
    &__controls{
      display: none;
    }
    &__img{
      height: 480px;
    }
    &__item{
      padding-bottom:63px;
      display: block;
      text-align: center;
      height: 100% !important;
      &:last-child{
        padding-bottom: 0;
      }
      img{
        max-height: 480px;
        height: 100%;
      }
      &.swiper-slide{
        height: initial;
      }
      &-content{
        display: block;
        padding: 0 174px 30px 90px;
        width:100%;
        text-align: left;
        .count{
          margin-top: 46px;
        }
      }
    }
  }
  .swiper-container {
    position: relative;
    overflow: inherit;
    &:after{
      display: none;
    }
  }
  //test
  .test{
    background: #fff;
    margin: 0;
    height: 100%;
    text-align: center;
    &__copy{
      opacity:.5;
      visibility: visible;
    }
    &__box{
      margin-left: -10px;
      margin-top: 10px;
      justify-content: center;
      height: 410px;
      overflow: hidden;
      transition: all .25s;
      &:after{
        content:'';
        display: block;
        width:310px;
      }
      &-item{
        margin-left:10px;
      }
      &-aloneBlock{
        .test__box-item:last-child{
          width:610px;
        }
      }
    }
    &__item{
      width: 300px;
      height: 200px;
      margin-top:10px;
      img{
        width: 100%;
      }
    }
    &__controls{
      display: none;
    }
    &__add{
      display: block;
      font:800 18px/22px 'Proxima Nova';
      color: #000;
      width:100%;
      transition: all .25s;
      margin: 39px 0 38px;
      cursor: pointer;
      &--off{
        height: 0;
        margin: 0;
        display: none;
      }
      &-icon{
        display: block;
        position: relative;
        width: 10px;
        height: 2px;
        background: #000;
        margin:24px auto 0;
        &:after{
          content: '';
          height:10px;
          width:2px;
          background: #000;
          display: block;
          position: absolute;
          top: -4px;
          left: 4px;
        }
      }
    }
  }
  //partners
  .content-partners{
    display: flex;
    .content{
      &__left{
        padding: 90px 40px 50px 50px;
        flex:0 0 300px;
      }
    }
  }
  .partners{
    padding-top: 102px;
    height: 100%;
    &__wrap{
      flex-direction: column;
    }
    &__develop{
      margin: 41px 0 0;
    }
  }
  //video
  .video{
    &__item{
      background: #e0deeb;
      height: 166px;
      &:hover{
        .video{
          &__img-box{
            padding:0;
          }
        }
      }
    }
    &__img{
      background-color: #655c9f;
      background-blend-mode: screen;
      margin-right: 6px;
      &-box{
        height: 108px;
        width: 108px !important;
      }
      &:after{
        opacity: .5;
      }
      img{
        filter: grayscale(100%);
      }
    }
    &__item-blue{
      background-color: #d6e8fe;
      .video{
        &__img:after{
          background-color: #dcecff;
        }
      }
    }
  }
  .video-player{
    &__controls{
      padding:0 25px;
    }
    &__play{
      width: 70px;
      height:70px;
      &--pause{
        .video-player__play-icon{
          &:before,
          &:after{
            left:19px;
          }
        }
      }
      &-icon{
        width:50px;
        height:50px;
        margin:0;
        transform: translate(-50%, -50%);


      }
      &:after{
        width:100%;
        height:100%;
        margin:0;
        transform: translate(-50%, -50%);
      }
      &:hover{
        &:after{
          width:100%;
          height:100%;
          margin:0;
        }
      }
    }
  }
  //context
  .slider{
    &__content{
      &-context.swiper-container{
        overflow: hidden;
        height: 100%;
        .slider{
          &__item{
            height:inherit;
          }
        }
      }
    }
    &__paginator{
      &-context{
        margin:0 0 32px -22px;
      }
    }
  }
  .context__next{
    height: 121px;
    &-text{
      position: initial;
    }
  }
  .context-bullet{
    display: inline-block;
    width: inherit;
    margin: 0 0 10px 22px;
    &.swiper-pagination-bullet-active{
      &:before{
        display: none;
      }
      &:after{
        content:'';
        display: block;
        border-bottom:3px solid #000;
      }
    }
    &.swiper-pagination-bullet{
      height:initial;
    }
    &~&{
      margin: 0 0 10px 22px;
    }
  }
  .context{
    &__next{
      .context-bullet{
        margin:0;
      }
    }
  }
  //test_grown
  .test-grown{
    padding:0 60px 20px;
    &__item{
      padding: 7px;
    }
    &__box{
      height: 637px;
      justify-content: flex-start;
    }
    &__img{
      img{
        height:308px;
      }
    }
    &__animated{
      display: none;
    }
    &-in{
      background: #fff;
    }
  }
  //test_grown-in
  .test-grown-in{
    display: block;
    height: 100%;
    .back-button{
      //display: none;
    }
    &__left{
      display: block;
      width:100%;
      text-align: center;
    }
    &__right{
      display: block;
      margin:0;
      padding:64px 120px 72px 100px;
      width:100%;
    }
    &__img{
      height: 430px;
      img{
        max-height: 430px;
      }
    }
    &__head{
      display: flex;
      margin-bottom:36px;
    }
    &__index{
      width: 50%;
      margin-bottom:0;
    }
    &__title{
      width: 50%;
      margin-bottom:0;
    }
    &__content{
      min-width: 100%;
    }
  }
  .test-grown-slider{
    overflow: hidden;
    height: 100%;
    &__controls{
      bottom: 10px;
    }
    &__item{
      flex-direction: column;
      display: block;
    }
  }
  //context
  .slider-context{
    overflow: inherit;
  }
  //test_boys(and girls)
  .section-boys,
  .section-girls{
    .container{
      padding:20px;
    }
    .main-menu{
      &__toggle-box{
        left: -105px;
      }
      &--active{
        .main-menu__toggle-box{
          left: 0;
        }
      }
    }
    .overflow-content{
      left: -101px;
      z-index: 0;
      width: 401px;
      &--hide{
        opacity:1;
        visibility: visible;
      }
    }
    .div{
      height: 100%;
    }
    .content{
      flex-direction: column-reverse;
      height: 100%;
      margin-left:101px;
      min-height:100%;
      overflow: inherit;
      display: flex;
      &__left{
        width:100%;
        padding:37px 110px 58px 27px;
        border:1px solid #DFDFDF;
        border-top: none;
        z-index: 1;
        height:initial;
        min-height:initial;
        display: block;
      }
      &__right{
        height: 100%;
        width:100%;
        border:1px solid #DFDFDF;
        background: #fff;
        z-index: 1;
        overflow: inherit;
      }
    }
    .button-box{
      &__content{
        margin: 120px 0 0 4px;
      }
    }
    h1{
      font-size: 40px;
      line-height: 49px;
      margin-top: 66px;
      &:after{
        display: none;
      }
    }
    .button{
      min-width:240px;
      margin-left: 0;
      &-black{
        min-width: 180px;
      }
      &-purple{
        min-width: 140px;
      }
      &__text-hover{
        top: 150%;
      }
    }
    .button-box{
      &__wrap{
        p{
          font-size:16px;
        }
      }
      &__wrap~&__wrap{
        margin-left: 20px;
      }
    }
    .pb_feed {
      margin-top: -10px;
    }
    //test_boys
    .section-boys{
      padding:10px 0 40px;
      .lang-switch{
        display: none;
      }
    }
    //test_girls
    .section-girls{
      padding:10px 0 40px;
      .lang-switch{
        display: none;
      }
    }
  }
  //test_choice
  .section-choice{
    .main-menu{
      &__toggle-box{
        left: -4px;
      }
    }
    .content{
      margin-left: 0;
      flex-direction: column;
      &__left{
        padding:90px  102px 0;
        min-height: 425px;
        h3{
          margin-bottom: 20px;
          font-size: 40px;
          line-height: 49px;
        }
      }
    }
    .more__box--choice{
      padding:0;
      .more__box{
      }
    }
    .total-text{
      &--small{
        margin-bottom: 0;
        font:normal 16px/26px 'Proxima Nova';
      }
    }
    .choice{
      &__box{
        min-height: 500px;
        .count{
          width: 84px;
          top: 60px;
          right: 104px;
          width: 168px;
          &~.count{
            bottom: 60px;
            left: 104px;
            right:inherit;
            top:inherit;
          }
          &__item{
            border-left: 42px solid #000;
            border-top: 37px solid transparent;
          }
        }
      }
    }
    .button{
      &--all-black{
        min-width: 312px;
        background: #000;
      }
    }
  }
  //add materials
  .materials{
    height:auto;
    &__wrap{
      padding: 106px 0 90px;
    }
    &__item{
      .button-black{
        &:hover{
          background: transparent;
          color: #000;
          .button__text{
            &-hover{
              top: 50%;
            }
          }
        }
        .button__text{
          &-hover{
            top: 150%;
          }
        }
      }
    }
  }
  .content--materials{
    h3{
      font-size: 40px;
      line-height: 49px;
    }
  }
  //popup
  .popup-wrap{
    &--mini{
      width: 100%;
      padding: 20px;
    }
  }
  .popup-item{
    &__mini.active{
      width: 100%;
    }
  }
  .help{
    width: 100%;
    flex-direction: column;
    &__text{
      max-width: 314px;
    }
    &__left{
      border-right: none;
      border-bottom:1px solid #e8e8e8;
    }
    &__left,
    &__right{
      width: 100%;
    }
    &__contacts{
      display: inline-block;
      margin-top: 18px;
      width: 248px;
      &~&{
        margin: 18px 0 0 64px;
      }
    }
    .facebook{
      margin-top: 32px;
    }
  }
}
@media all and (max-width: 767px) {
  //layout
  .wrapper{
    display: flex;
    flex-direction: column;
  }
  .section-index{
    &.preload {
      .container{
        padding-bottom: 0;
      }
    }
    &:first-child{
      order: 1;
    }
    .layout__controls{
      margin-top: 30px;
      .info{
        color: #fff !important;
      }
      .help__button{
        color: #fff !important;
      }
      .logo svg{
        fill: #fff !important;
      }
    }
  }
  .animation-content__bottom{
    margin-bottom:30px;
  }
  .content{
    &__head{
      position: initial;
      overflow: hidden;
      width: 100%;
    }
  }
  .description-text{
    align-self: center;
    //margin-top: -25px;
  }
  //popup
  .popup-wrap{
    padding:0px;
  }
  .help{
    &__left,
    &__right{
      padding: 26px 20px 40px;
    }
    &__header{
      min-height:auto;
    }
    &__title{
      font:800 32px/40px 'Proxima Nova';
    }
    &__contacts{
      margin-top: 26px;
      width: 100%;
      &~&{
        margin:16px 0 0;
      }
      &-number{
        font:800 22px/26px 'Proxima Nova';
      }
    }
    .facebook{
      margin-top: 20px;
    }
  }
  .saw{
    height: 358px;
    bottom:245px;
  }
  .container{
    padding:10px;
  }
  li{
    font-size: 16px;
    line-height: 40px;
  }
  .content-border{
    padding:30px;
  }
  p{
    font-size: 16px;
    line-height: 20px;
    &+p{
      margin-top: 21px;
    }
  }
  h1{
    margin-top: 23px;
    &:after{
      width: 45px;
    }
  }
  .button-box{
    flex-direction: column;
    margin-top: 41px;
  }
  .button{
    &~&{
      margin-top:17px;
    }
  }
  .main-menu{
    &__top,
    &__buttom{
      display: none;
    }
    &__dropdown{
      //margin: 10px;
      //width: calc(100% - 20px);
      padding: 56px 0 40px;
    }
    &__title,
    &__item,
    &__index,
    &__h{
      padding-left: 81px;
      width: 81%;
      &:after{
        left: 60px;
      }
    }
    &__item{
      &-info{
        display: block;
      }
      &--active{
        &:before{
          left: 56px;
        }
      }
    }
  }

  .lang-switch{
    top: 24px;
    right: 12px;
  }
  .layout__controls{
    order: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo{
      position: static;
      margin:20px 0 32px;
    }
    .help__button{
      position: static;
      margin-bottom: 24px;
    }
    .info{
      position: static;
      margin-bottom: 0;
    }
  }
  //index
  .text-white{
    p{
      font-size: 16px;
    }
  }
  .content-border{
    &__left,
    &__right{
      width:100%;
    }
    &__top{
      flex-direction: column-reverse;
      .content-border{
        &__right{
          margin-left:auto;
        }
      }
    }
    &__middle{
      margin-top: 16px;
      &>*{
        margin-top:0;
      }
      .content-border{
        &__right{
          margin-top: 17px;
        }
      }
    }
    &__bottom{
      margin-top: 15px;
      &>*{
        margin-top: 0;
      }
      .content-border{
        &__left{
          margin-top: 48px;
        }
      }
    }
  }
  .animation-content{
    &__item{
      padding: 30px;
    }
    &__box{
      position: absolute;
      top: 50%;
      transform: translate(0,-50%);
    }
  }
  //kids
  .help-page {
    width: 240px;
    &__left {
      .facebook {
        margin-top: 20px;
      }
    }
    &__right {
      padding-top: 0;
    }
  }
  .content{
    &__left{
      padding:92px 30px 30px;
    }
    &__right {
      padding: 0 30px 40px;
    }
    &__head{
      .count{
        margin:0;
        position: absolute;
        top: 30px;
        right: 30px;
      }
    }
  }
  .slider{
    &__item{
      padding:0 0 33px;
      img{
        max-height: 360px;
      }
      &-content{
        padding:0 30px 30px;
        .count{
          margin-top: 37px;
        }
      }
    }
  }
  //video
  .video{
    height:100%;
    &__item{
      flex-direction: column-reverse;
      text-align: center;
      padding:35px 0;
      height: 100%;
      &:hover{
        .video{
          &__img{
            width: 200px;
            height: 200px;
            img{
              height: 100%;
            }
          }
        }
      }
    }
    &__title{
      font-size: 40px;
      line-height: 48px;
      margin-top: 30px;
      height:auto;
    }
    &__img{
      &-box{
        width: 200px !important;
        height: 200px;
      }
      img{
        height: 200px;
      }
    }
  }
  .video-player{
    &__controls{
      flex-wrap: wrap;
      padding:0 10px;
      bottom: 20px;
    }
    &__timeline{
      order: 1;
      flex: 1 0 100%;
      margin:0;
    }
    &__play{
      display: none;
      order: 2;
    }
    &__volume-box{
      display: none;
      order:3;
    }
  }
  //test
  .test{
    padding:0;
    &__box{
      margin:-10px 0 0 -10px;
      height: 776px;
      padding-bottom:10px;
      &-item{
        &:nth-child(odd){
          .test__item:last-child{
            height: 168px;
          }
        }
        &:nth-child(even){
          .test__item:first-child{
            height:200px;
          }
          .test__item:last-child{
            height:168px;
          }
        }
      }
    }
    &__copy{
      opacity:.5;
      visibility: visible;
    }
    &__add{
      margin: 55px 0;
      &--off{
        margin:0;
      }
    }
  }
  //advice
  .advice{
    display: block;
    &__item{
      display: block;
      height: 100%;
      padding:40px 30px 35px;
    }
    &__text{
      width:100%;
    }
    &__right{
      width:100%;
      text-align: center;
      margin:27px 0 0;
      display: block;
    }
    &__icon{
      margin:0 auto;
    }
    &__left{
      text-align: center;
      display: block;
    }
    &__text{
      text-align: left;
    }
    &__link{
      margin-top: 25px;
    }
  }
  //advice_in
  .advice-in{
    &__avatar{
      margin:0 auto;
    }
    &__head{
      padding: 50px 30px 33px;
      position: relative;
      &-content{
        width:100%;
        margin:0;
        text-align: center;
      }
    }
    &__left{
      display: block;
      width: 100%;
    }
    &__back{
      font-size: 0;
      position: absolute;
      top: 0;
      line-height: 0;
      padding: 18px;
      right: 0;
      &:before{
        top:50%;
        left:50%;
        border-top: 5px solid transparent;
        border-right: 8px solid #fff;
        border-bottom: 5px solid transparent;
        margin:-4px 0 0 -5px;
      }
    }
    &__social{
      text-align: center;
      margin:20px 0 0;
      &-item{
        display: inline-block;
      }
    }
    &__title{
      margin-top: 41px;
    }
    &__content{
      padding:25px 30px 60px;
    }
  }
  //partners
  .content-partners{
    display: block;
    .content{
      &__left{
        padding: 82px 30px 32px;
      }
    }
  }
  .partners{
    padding:0 30px 19px;
    &__content{
      text-align: center;
      display: block;
      margin-left:0;
    }
    &__box,
    &__develop{
      display: flex;
      flex-direction: column-reverse;
    }
    &__item{
      margin:0 auto;
    }
    &__wrap{
      margin-top: 19px;
    }
    &__text{
      text-align: center;
    }
  }
  //test_grown
  .test-grown{
    &__box{
      display: block;
      width:100%;
      margin:0;
      height: 628px;
      padding:0;
    }
    &__item{
      display: block;
      width:100%;
      padding:0;
      padding-bottom: 12px;
    }
  }
  //test_grown-in
  .test-grown-in{
    &__right{
      padding:18px 24px 38px;
    }
    &__head{
      display: block;
    }
    &__index{
      width: 100%;
      margin-bottom: 12px;
    }
    &__title{
      width:100%;
      margin-bottom: 28px;
    }
    &__img{
      height: 308px;
      img{
        height: 308px;
      }
    }
  }
  //context
  .slider__paginator-context{
    position: absolute;
    top:50px;
    left:22px;
    width:100%;
    visibility: hidden;
    opacity:0;
    background: #d7e9ff;
    transition: all .25s;
    &--open{
      opacity:1;
      visibility: visible;
    }
    &-box{
      width: calc(100% + 60px);
      background: #d7e9ff;
      margin-left: -30px;
      position: relative;
      z-index: 2;
    }
    &-active{
      display: block;
      padding-left: 40px;
      position: relative;
      &:after{
        content:'';
        display: inline-block;
        border-left:5px solid transparent;
        border-right:5px solid transparent;
        border-top:8px solid #000;
        position: absolute;
        right: 45px;
        top: 21px;
        transition: all .25s;
      }
      &--open:after{
        transform: rotate(180deg);
      }
    }
    .context-bullet{
      display: block;
      padding:12px 0 12px 40px;
      margin:0;
      &:after{
        display: none;
      }
    }
  }
  .context{
    &__next{
      display: none;
    }
  }
  .slider__content-context.swiper-container{
    height:100%;
    margin-top: -30px;
    .slider{
      &__item{
        &-content{
          flex-direction: column-reverse;
          justify-content: flex-end;
        }
        &-right{
          padding:35px 0 15px;
        }
        &-left{
          padding:0;
          text-align: center;
          min-width: 100%;
        }
        &-content{
          padding:0;
        }
        &-head{
          width:100%;
          margin:0;
          h3{
            margin-bottom: 15px;
          }
        }
        &-index{
          display: none;
        }
        &-title{
          width:100%;
        }
        &-avatar{
          margin:0 auto;
        }
      }
    }
    .swiper-slide{
      height: inherit;
      padding:0 26px;
    }
  }
  .slider-context{
    padding-bottom: 20px;
    background: #f4f4f4;
  }

  //test_boys(and girls)
  .section-boys,
  .section-girls{
    .container{
      padding:10px;
    }
    .overflow-content{
      display: none;
    }
    .button{
      min-width: 100%;
      width: 100%;
      &-box{
        margin-top: 15px;
        &__content{
          overflow: initial;
        }
        &__wrap~&__wrap{
          margin:54px 0 0;
        }
      }
    }
    .content{
      &__left{
        padding:0 0 40px;
        border: none;
        background: #655c9f;
      }
    }
    .total-text{
      margin:49px 0 30px;
      display: none;
    }
    .button~.button{
      margin:20px 0 0;
    }
    .button-box{
      margin:0;
      &__content{
        display: block;
      }
      .button{
        &-purple{
          border: none;
          color: #000;
          padding: 20px 0;
          margin-top: 30px;
          box-shadow: none;
          font-size: 14px;
          line-height: 18px;
          &:after{
            display: none;
          }
          &:hover{
            .button__text{
              top: 0;
              opacity: 1;
              &-hover{
                display: none;
              }
            }
          }
        }
      }
    }
    .logo,
    .info{
      bottom:28px;
    }
    .more{
      &__item{
        display: flex;
      }
      &__box{
        padding-left:0;
      }
    }
    .wrapper{
      background: #F4F4F4;
      align-items: flex-start;
    }
    .content{
      margin:0 0 60px;
      height: 100%;
      min-height: 100%;
      &__right{
        border: none;
        padding-top: 50px;
        min-height: 412px;
      }
      .main-menu{
        &__toggle-box{
          left: -4px;
          top: -4px;
        }
      }
    }
    .pb_feed{
      margin-left:0;
    }
    //index
    .content-border{
      padding:28px 28px 80px;
      &__left{
        width: 100%;
        display: block;
      }
      &__right{
        flex:0 0 0;
      }
      &__bottom{
        margin-top: 78px;
        display: block;
      }
    }
    .section-index{
      .lang-switch{
        top:45px;
        right: 30px;
      }
    }
    //test_boys
    .section-boys{
      padding:0 0 0px;
      background: #F4F4F4;
      .info{
        color: #7D7D7D;
      }
      .logo{
        svg{
          fill:#7D7D7D;
        }
      }
      .lang-switch{
        display: none;
      }
    }
    //test_girls
    .section-girls{
      padding:0 0 0px;
      background: #F4F4F4;
      .info{
        color: #7D7D7D;
      }
      .logo{
        svg{
          fill:#7D7D7D;
        }
      }
      .lang-switch{
        display: none;
      }
    }
    .main-menu{
      &__toggle-box{
        left: 2px;
        top: 2px;
      }
    }
    .button{
      margin-left: -4px;
    }
  }
  //choice
  .section-choice{
    .container{
      padding: 10px;
    }
    .main-menu{
      &__toggle-box{
        left: -4px;
        top: -4px;
      }
    }
    .more{
      &__box{
        height: 100%;
      }
    }
    h3{
      font-size: 28px;
      line-height: 34px;
      margin-bottom: 12px;
    }
    .total-text{
      margin:0;
      &--small{
        display: block;
        font-weight: 800;
      }
    }
    .content{
      &__left{
        min-height: 100%;
        padding: 100px 24px 54px;
        background: #fff;
        h3{
          font-size: 28px;
          line-height: 34px;
        }
      }
      &__right{

      }
    }
    .choice{
      &__box{
        min-height: 366px;
        .count{
          width: 84px;
          right: 25px;
          top: 25px;
          &~.count{
            bottom: 25px;
            left: 25px;
            top:initial;
            right:initial;
          }
          &__item{
            border-left: 21px solid #000;
            border-top: 18px solid transparent;
          }
        }
      }
    }
    .button{
      margin:0;
      &--all-black{
        min-width: 250px;
      }
    }
  }
  //add materials
  .materials{
    height:auto;
    padding:0 20px;
    &__wrap{
      padding: 18px 0 30px;
    }
    &__box{
      text-align: center;
    }
    .button{
      width: 130px;
    }
  }
}