// layout
.hr{
  width: 230px;
  min-height: 7px;
  background: #000;
  margin:25px 0 26px;
}
.overflow{
  overflow: hidden;
}
.back-button{
  width: 50px;
  height: 50px;
  background: #000;
  transition: all .25s;
  padding: 15px 12px;
  display: block;
  position: absolute;
  right: 0;
  top:0;
  z-index: 2;
  cursor: pointer;
  &:after,
  &:before {
    content: '';
    width: 26px;
    height: 4px;
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -2px 0 0 -13px;
    transition: all .25s;
    transform: rotate(45deg);
  }
  &:before {
    transform: rotate(-45deg);
  }
  &:hover{
    background: #fff;
    &:after,
    &:before {
      //width: 34px;
      //margin-left: -17px;
      background: #000;
    }
  }
  &__gray{
    background:  none;
    padding: 18px 18px;
    &:before,
    &:after{
      background: #cccccc;
      width: 18px;
      height: 3px;
      margin: -2px 0 0 -9px;
    }
    &:hover{
      &:before,
      &:after{
        background: #cccccc;
      }
    }
  }
  &__white{
    background: #fff;
    &:after,
    &:before{
      background: #000;
    }
    &:hover{
      background: #000;
      &:after,
      &:before{
        background: #fff;
      }
    }
  }
}
.animation-layout{
  width:100%;
  height:100%;
  background: #e1ecf9;
  position: absolute;
  left:0;
  top:0;
  z-index: 4;
  overflow: hidden;
  .saw{
    background: #000;
    &:after{
      background-image: linear-gradient(to bottom left, transparent 50.5%, #000 50.5%);
      animation: saw 6s cubic-bezier(0,1.04,.25,0.9) 1;
    }
  }
  &__item{
    display: flex;
    flex-direction: column;
  }
}
.section-index{
  .animation-content{
    bottom: 0;
  }
}
.animation-content{
  width:100%;
  height:100%;
  background: #fff;
  position: absolute;
  left:0;
  bottom:0;
  transition: all 1s;
  z-index: 4;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  &__none{
    height:0;
    &.animation-content--start{
      height: 100%;
    }
  }
  &__off{
    display: none;
  }
  &__black{
    background: #000;
  }
  &__start-top{
    &.animation-content--start{
      top:0;
    }
    &.animation-content--active{
      bottom: 0;
    }
  }
  &--start{
    height:0;
  }
  &--active{
    height:100%;
  }
  &__count{
    font:800 100px/110px 'Proxima Nova';
    color: #000;
    width:246px;
  }
  &__progress{
    height:10px;
    width:0%;
    background: #000;
    transition: all .25s;
    &-box{
      width: 245px;
      margin-left: 38px;
    }
  }
  &__top{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &__bottom{
    margin-top:auto;
    display: flex;
    justify-content: flex-start;
  }
  &__box{
    display: flex;
    align-items: center;
  }
  &__item{
    top: 0%;
    position: absolute;
    padding:48px;
    min-height: 500px;
    height:100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
.count__animated{
  &.count{
    position: relative;
    top:-76px;
    transition: all .25s linear;
  }
  .count__item{
    transition: all .25s linear;
    position: relative;
    bottom:100%;
  }
  &-bottom{
    &.count{
      position: relative;
      bottom:-76px;
      transition: all .25s linear;
    }
    .count__item{
      transition: all .25s linear;
      position: relative;
      bottom:-100%;
    }
  }
}
.count__animated--active{
  &.count{
    position: relative;
    top:0px;
  }
  .count__item{
    bottom:0;
  }
  &.count__animated-bottom{
    &.count{
      position: relative;
      bottom:0;
      top:inherit;;
      transition: all .25s linear;
    }
    .count__item{
      transition: all .25s linear;
      position: relative;
      bottom:0;
    }
  }
}
.preload{
  .animation-content{
    transition: all .5s;
    height:0;
    &__item{
      transition: all 0.5s;
      transform: translateY(-100%);
    }
  }

  .animation-layout{
    transition: all .5s;
    transform: translateY(-100%);
    .saw{
      transition: all .5s;
      position: absolute;
      bottom: -100%;
      left:0;
    }
  }
}
  //end layout
section{
  height: 100%;
  width:100%;
  display: flex;
  align-items: center;
}
.section-index{
  background: #000;
  .lang-switch{
    &__item{
      color: #fff;
      &--active{
        &:after{
          border-top-color: #fff;
        }
      }
    }
  }
  .info{
    color: #fff;
  }
  .help__button{
    color: #fff;
  }
  .count{
    width: 168px;
  }
  .main-menu {
    &__toggle {
      top: 4px;
      left: 4px;
    }
  }
}

.preload {
  .main-menu {
    &__toggle {
      //top: -2px;
      //left: -2px;
      top:0;
      left:0;
      &-box {
        left: -6px;
        top: -6px;
      }
    }
  }
}


.button-box{
  width:100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  &__content{
    margin-top: auto;
  }
}
.button{
  border:2px solid #fff;
  min-width:170px;
  color: #fff;
  font:800 18px/20px 'Proxima Nova';
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding:15px 0;
  text-decoration: none;
  cursor: pointer;
  letter-spacing: -0.2px;
  transition: all .25s;
  overflow: hidden;
  position: relative;
  &-black{
    color: #000;
    border-color: #000;
    min-width: 180px;
    overflow: hidden;
    &:hover{
      &:after{
        background: #000;
      }
    }
    .button__text-hover{
      color: #fff;
    }
  }
  &-purple{
    color: #9D96CC;
    border-color: #9D96CC;
    overflow: hidden;
    min-width: 140px;
    &:hover{
      &:after{
        background: #9D96CC;
      }
    }
    .button__text-hover{
      color: #fff;
    }
  }
  &__text{
    position: relative;
    transition: all .25s;
    top:0;
    &-hover{
      color: #000;
      position: absolute;
      left:50%;
      top:150%;
      transform: translate3D(-50%, -50%, 0);
      transition: all .25s;
      z-index: 2;
      width: 100%;
      text-align: center;
    }
  }
  &:after{
    content:'';
    display: block;
    position: absolute;
    bottom:0;
    left:0;
    height:0;
    width:100%;
    transition: all .25s;
    background: #fff;
  }
  &:hover{
    box-shadow: 0 -6px 34px 0 rgba(255, 255, 255, 0.5);
    &:after{
      height:100%;
    }
    .button{
      &__text{
        top:-150%;
        opacity:0;
        &-hover{
          top:50%;
        }
      }
    }
  }
  &--all-black{
    background: #000;
    border-color: #000;
    display: block;
    min-width: 312px;
    text-align: center;
    font-size: 16px;
    &~&{
      margin-top: 30px;
    }
    &:after{
      display: none;
    }
  }
}
.saw{
  height: 50vh;
  width:100%;
  position: fixed;
  bottom:-25vh;
  left:0;
  background: #655c9f;
  transition:  1s;
  transition-property: top,bottom;

  &:after,
  &:before{
    content:'';
    display: block;
    background-image:
            linear-gradient(to bottom left, transparent 50.5%, #655c9f 50.5%);
    background-repeat: repeat-x, repeat-x;
    background-position: 29px 0, 10px 0;  /*  начинаем не с обрезанного угла  */
    background-size: 30px 18px, 20px 20px;
    width:100%;
    height:18px;
    top:-18px;
    position: absolute;
  }
  &:before{
    top:100%;
    background-image:
            linear-gradient(to bottom left, #655c9f  50.5%,transparent  50.5%);
  }
  &__black{
    background: #000;
    &:after,
    &:before{
      background-image:
              linear-gradient(to bottom left, transparent 50.5%, #000 50.5%);
    }
    &:before{
      background-image:
              linear-gradient(to bottom left, #000 50.5%, transparent 50.5%);
    }
  }
  &__top{
    bottom:inherit;
    top: -25vh;
  }
  &--active{
    &.saw__top{
      top: calc( 100% - 25vh);
      &:after,
      &:before{
        animation: saw-left 1s linear;
      }
    }
    bottom: calc( 100% - 25vh );
    &:after,
    &:before{
      animation: saw-left 1s linear;
    }
  }
  &--left{
    &:after,
    &:before{
      animation: saw-left 1s linear;
    }
  }
}
@keyframes  saw{
  from { background-position: -500% 100%, 120% 30%, 120% 15%, 0 0;}
  to { background-position: 0 500%, -200% 10%, -50% 15%, 0 0; }
}
@keyframes  saw-left{
  from { background-position: -30% 100%, 120% 30%, 120% 15%, 0 0;}
  to { background-position: 0 30%, -200% 10%, -50% 15%, 0 0; }
}
.line{
  *{
    display: inline-block;
    position: relative;
    padding: 0 48px 0 15px;
    margin-left:-15px;
    &:after{
      position: absolute;
      content: '';
      display: inline-block;
      height: 5px;
      background: #000;
      width:100%;
      top:50%;
      left:0;
    }
  }
}
.lang-switch{
  position: absolute;
  right:50px;
  top:5vh;
  margin-top: -19px;
  z-index: 2;
  transition: all .25s;
  &--open{
    background: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
    z-index: 3;
    .lang-switch{
      &__item{
        color: #000;
        &--active:after{
          border-top:4px solid #000;
          transform: rotate(90deg);
        }
      }
      &__dropdown{
        opacity:1;
        visibility: visible;
      }
    }
  }
  &__item{
    font:normal 14px/18px 'Proxima Nova';
    color: #000;
    padding:10px 0 10px 9px;
    transition: all .25s;
    letter-spacing:-0.1px;
    cursor: pointer;
    display: block;
    width:50px;
    text-decoration: none;
    position: relative;
    &:after{
      content:'';
      display: block;
      width:0;
      border-left:4px solid transparent;
      border-right:4px solid transparent;
      border-top:4px solid #000;
      position: absolute;
      right:6px;
      top:50%;
      margin-top:-2px;
      transition: all .25s;
      opacity:0;
      transform: rotate(90deg);
    }
    &--active{
      position: relative;
      &:after{
        content:'';
        display: block;
        width:0;
        border-left:4px solid transparent;
        border-right:4px solid transparent;
        border-top:4px solid #000;
        position: absolute;
        right:6px;
        top:50%;
        margin-top:-2px;
        transition: all .25s;
        opacity: 1;
        transform: none;
      }
    }
    &:hover:after{
      opacity:1;
    }
  }
  &__dropdown{
    visibility: hidden;
    opacity:0;
    transition: all .25s;
  }
}
.content-border {
  .lang-switch{
    right: -2px;
  }
}
.off-delay{
  transition-delay: 0s !important;
  *{
    transition-delay: 0s !important;
  }
  *:after{
    transition-delay: 0s !important;
  }
}
//.saw+section{
.saw{
  &~.layout__controls{
    .lang-switch{
      &--active{
        .lang-switch__item{
          transition: all .25s;
          color: #fff;
          transition-delay: .7s;
          &--active:after{
            transition-delay: .7s;
            border-top: 4px solid #fff;
          }
        }
        &.lang-switch--open{
          .lang-switch__item{
            transition: all .25s;
            color: #000;
            transition-delay: .7s;
            &--active:after{
              transition-delay: .7s;
              border-top: 4px solid #000;
            }
          }
        }
      }
    }
    .info{
      transition-delay: .5s;
      color: #fff;
      &:after{
        background: #000;
      }
      &--active{
        color: #000;
      }
    }
    .help__button{
      color: #fff;
      &--active{
        color: #000;
      }
    }
  }
}
//.saw__top+section{
.saw__top{
  &~.layout__controls{
    .lang-switch{
      .lang-switch__item{
        color: #fff;
        &--active:after{
          border-top: 4px solid #fff;
        }
      }
      &--open{
        background: #fff;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
        .lang-switch{
          &__item{
            color: #000;
            &--active:after{
              border-top:4px solid #000;
              transform: rotate(90deg);
            }
          }
          &__dropdown{
            opacity:1;
            visibility: visible;
          }
        }
      }
      &--active{
        .lang-switch__item{
          transition: all .25s;
          color: #000;
          transition-delay: .5s;
          &--active:after{
            transition-delay: .5s;
            border-top: 4px solid #000;
          }
        }

      }
    }
    .info{
      transition-delay: .7s;
      color: #000;
      &:after{
        background: #fff;
      }
      &--active{
        color: #fff;
      }
    }
    .help__button{
      color: #000;
      &--active{
        color: #fff;
      }
    }
  }
}
//saw static
.saw.saw__top.saw--static{
  &~.lang-switch{
    .lang-switch__item{
      color: #fff;
      &:after{
        border-top: 4px solid #fff;
      }
      &--active:after{
        border-top: 4px solid #fff;
      }
    }
    &--active{
      .lang-switch__item{
        transition: all .25s;
        color: #fff;
        transition-delay: .5s;
        &--active:after{
          transition-delay: .5s;
          border-top: 4px solid #fff;
        }
      }
    }
    &--open{
      background: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16);
      .lang-switch{
        &__item{
          color: #000;
          &--active:after{
            border-top:4px solid #000;
            transform: rotate(90deg);
          }
        }
        &__dropdown{
          opacity:1;
          visibility: visible;
        }
      }
    }
  }
  &~.info{
    transition-delay: .7s;
    color: #000;
    &:after{
      background: #000;
    }
    &--active{
      color: #000;
    }
  }
}
.saw.saw--static{
  &~.lang-switch{
    &__item{
      color: #000;
      &--active:after{
        border-top: 4px solid #000;
      }
    }
    &--active{
      .lang-switch__item{
        transition: all .25s;
        color: #000;
        transition-delay: .5s;
        &--active:after{
          transition-delay: .5s;
          border-top: 4px solid #000;
        }
      }
    }
  }
  &~.info{
    transition-delay: .7s;
    color: #fff;
    &:after{
      background: #fff;
    }
    &--active{
      color: #fff;
    }
  }
}
//saw end static
.help__button{
  font:normal 14px/18px 'Proxima Nova';
  letter-spacing: -0.13px;
  padding: 7px 10px;
  position: absolute;
  bottom: 5vh;
  margin-bottom: -16px;
  right: 347px;
  z-index: 1;
  text-decoration: none;
  color: #000;
  background: rgba(255,255,255,.1);
}
.info{
  font:normal 14px/18px 'Proxima Nova';
  color: #000;
  letter-spacing: -0.1px;
  position: absolute;
  //bottom:5vh;
  bottom:5vh;
  margin-bottom:-9px;
  text-decoration: none;
  right:50px;
  z-index: 1;
  &:after{
    content: '';
    display: inline-block;
    width:0;
    position: absolute;
    left:0;
    bottom:-2px;
    height:2px;
    background: #fff;
    transition: all .25s;
  }
  &:hover{
    &:after{
      width:100%;
    }
  }
  &--materials{
    right: 154px;
  }
}
//header



//end header

//footer

//end footer

//form

//end form