@media all and (min-width: 1024px)
and (max-width: 1399px) {
  .slider__text {
    &.swiper-container {
      min-height: 44vh;
    }
  }

  .content {
    &__left {
      &-help {
        padding: 50px 0 50px 0;
      }
    }
    &__right {
      &-help {
        padding: 50px 0 0 50px;
      }
    }
  }
  .help-page {
    &__right,
    &__left {
      padding-top: 50px;
    }
    &__left {
      padding-right: 50px;
    }
  }
}
@media (min-width:1024px){
  .hide-on-desktop{
    display:none;
  }
}

@media all and (min-width: 1400px) {
  //layout
  .content__head-mini{
    h3{
      font-size: 34px;
    }
  }
  h1{
    font-size: 100px;
    line-height: 120px;
    margin-top: 0;
    &:after{
      width: 120px;
      margin-left: 50px;
    }
  }
  h3{
    font-size: 44px;
    line-height:46px;
    margin-bottom: 50px;
  }
  .description-text{
    margin-bottom: 50px;
  }
  .animation-content{
    &__item{
      padding: 5% 110px;
    }
  }
  .content-border{
    padding: 80px 110px;
    &__left,
    &__right{
      width: 500px;
    }
  }
  .button{
    &-box{
      justify-content: flex-start;
    }
    min-width: 180px;
    padding: 18px 0;
    &~&{
      margin-left: 30px;
    }
    &--all-black{
      font-size: 18px;
    }
  }
  .text-white p{
    font-size: 16px;
    line-height: 20px;
  }
  p{
    font-size: 18px;
    line-height: 26px;
    &+p{
      margin-top: 49px;
    }
  }
  .lang-switch{
    //min-width: 80px;
    //top: -70px;
    //&__item{
    //  font-size: 28px;
    //  line-height: 34px;
    //  width: 100%;
    //  padding:10px;
    //  &--active{
    //    &:after{
    //      right:10px;
    //      border-left: 9px solid transparent;
    //      border-right: 9px solid transparent;
    //      border-top: 8px solid #000;
    //    }
    //  }
    //}
  }
  //.saw + section .lang-switch--active .lang-switch__item--active:after {
  //  transition-delay: .7s;
  //  border-top: 8px solid #fff;
  //}
  //.saw + section .lang-switch--active.lang-switch--open .lang-switch__item--active:after {
  //  transition-delay: .7s;
  //  border-top: 8px solid #000;
  //}
  //.saw__top + section .lang-switch--active .lang-switch__item--active:after {
  //  transition-delay: .5s;
  //  border-top: 8px solid #000;
  //}
  .info{
    //font-size: 28px;
    //line-height: 34px;
    //bottom: -60px;
  }
  .main-menu{
    &--active{
      //.main-menu__toggle:after{
      //  top: 24px;
      //  left: 25px;
      //}
    }
    &__title,
    &__item,
    &__index{
      font-size: 18px;
      line-height: 26px;
      padding-left: 80px;
      &--active{
        &:before{
          left:58px;
        }
      }
    }
    &__h{
      font-size: 48px;
      padding-left:80px;
      margin: 14px 0 25px;
      //margin:78px 0 48px;
    }
    &__item{
      line-height: 24px;
      padding: 5px 0 5px 80px;
      &:after{
        left: 58px;
      }
    }
    &__index{
      margin:12px 0 30px;
    }
    &__title{;
      margin:12px 0 5px;
    }
  }
  //kids
  .content__left{
    .count{
      overflow: inherit;
    }
  }
  .slider{
    &__text{
      &.swiper-container{
        min-height: 46vh;
        max-height:100%;
      }
      &-item{
        &.swiper-slide{
          height:100%;
          //justify-content: flex-start;
        }
      }
    }
  }
  //video
  .video{
    //&__img{
    //  //width: 200px;
    //  //height:200px;
    //  width: initial;
    //  height: 80%;
    //  img{
    //    max-height: 200px;
    //    height: 100%;
    //  }
    //}
    &__item{
      padding: 0 32px 0 91px;
      &:hover{
        //.video{
        //  &__img{
        //    margin-right: 21px;
        //    //width: 160px;
        //    //height: 160px;
        //    width: initial;
        //    height: 70%;
        //    img{
        //      height: 100%;
        //    }
        //  }
        //}
      }
    }
    &__title{
      font-size: 48px;
      line-height: 58px;
    }
  }
  //advice
  .advice{
    &__title{
      font-size:28px;
      line-height: 34px;
    }
    &__text{
      margin-top: 21px;
      font-size: 17px;
      line-height: 21px;
    }
    &__icon{
      width: 175px;
      height: 175px;
    }
    &__right{
      min-width: 214px;
      width: 100%;
      margin-left: 85px;
      align-self: center;
    }
    &__item{
      padding:60px 70px;
      //min-height: 350px;
      position: relative;
    }
    &__link{
      //position: absolute;
      bottom:42px;
    }
  }
  //advice_in
  .advice-in{
    &__title{
      margin-top:0;
      font-size: 48px;
      line-height: 58px;
    }
    &__content{
      margin-left: 330px;
    }
    &__head{
      padding:88px 72px;
      position: relative;
      &-content{
        margin-left: 82px;
      }
    }
    &__social{
      margin-top: 50px;
    }
    &__avatar{
      width: 175px;
      height: 175px;
    }
    &__social{
      margin-bottom:0;
    }
    &__back{
      position: absolute;
      top:25px;
      right:56px;
      &:before{
        border-top: 5px solid transparent;
        border-right: 8px solid #fff;
        border-bottom: 5px solid transparent;
        left: -34px;
      }
    }
  }
  //partners

  .partners{
    display: flex;
    flex-direction: column;
    padding-right: 154px;
    &__wrap{
      flex-direction: row;
      justify-content: space-between;
      height: 100%;
      align-items: flex-end;
    }
    &__develop{
      margin-top:0;
      margin-left: 40px;
    }
    &__content{
      margin-left:-84px;
    }
    &__item{
      margin-left: 84px;
    }
  }
  .content-partners .content__left{
    flex:0 0 390px;
    width: 390px;
  }
  //context
  .slider{
    &__content-context{
      h3{
        font-size: 60px;
        line-height: 70px;
      }
      .slider{
        &__item{
          padding:0 0 14%;
          &-avatar{
            max-width: 360px;
            width: 100%;
            height:360px;
            margin-left:0;
          }
          &-index{
            margin-bottom: 60px;
          }
          &-content{
            align-self: center;
          }
          &-right{
            display: flex;
            padding:0;
            margin-right: 80px;
            width: 37%;
          }
          &-left{
            padding:0px 54px 0 63px;
            min-width: 362px;
          }
        }

      }
    }
  }
  .context{
    &__next{
      padding-left: 63px;
      &-text{
        left: 63px;
      }
    }
  }
  //test_grow
  .test-grown{
    &__box{
      padding:50px;
    }
    &__item{
      padding:10px;
    }
    &__img{
      text-align: center;
      img{
        max-height: 468px;
      }
    }
  }
  //test
  //.test__box{ /* contains the original content */
  //  padding:0 10% 15px;
  //}
  //test_grown-in
  .test-grown-in{
    &__right{
      width: 52%;
      margin-left: -80px;
    }
    &__left{
      width: 80%;
    }
    &__index{
      &--active {
        &:after {
          width: 55%;
        }
      }
      margin-bottom: 65px;
    }
    &__title{
      margin-bottom: 65px;
      padding-right: 80px;
    }
    &__text{
      padding-right: 80px;
    }
  }
  .content-grown{
    &__left{
      flex: 0 0 34%;
      padding-top: 128px;
    }
  }
  //choice
  .section-choice{
    .choice{
      &__box{
        .count{
          top: 60px;
          right: 60px;
          &~.count{
            left: 60px;
            bottom: 60px;
            top:initial;
            right:initial;
          }
        }
      }

    }
    h3{
      font-size: 48px;
      line-height: 58px;
    }
    .button--all-black{
      font-size: 18px;
    }
  }
  .total-text{
    &--small{
      font: normal 18px/26px 'Proxima Nova';
    }
  }
  //add materials
  .materials{
    &__item{
      width: 280px;
    }
    &__title{
      &~&{
        margin-top: 30px;
      }
    }
  }
  .content--materials{
    h3{
      font-size: 48px;
      line-height: 58px;
    }
  }
  //layout
  .content{
    &__left,
    &__right{
      //min-height: 680px;
    }
  }
}
@media all and (min-width: 1601px) {
  //layout
  h1{
    font-size: 100px;
    line-height: 120px;
    margin-top: 0;
  }
  h3{
    font-size: 48px;
    line-height:58px;
  }
  .animation-content{
    &__item{
      padding: 5% 190px;
    }
  }
  .content-bordpx{
    padding: 5% 190px;
    &__left,
    &__right{
      width: 500px;
    }
  }
  .content{
    &__left{
      flex: 0 0 590px;
      &-help {
        flex: 0 0 50%;
      }
    }
  }
  .button{
    &-box{
      justify-content: flex-start;
    }
    min-width: 180px;
    padding: 18px 0;
    &~&{
      margin-left: 30px;
    }
  }
  .text-white p{
    font-size: 18px;
    line-height: 22px;
  }
  p{
    font-size: 18px;
    line-height: 26px;
  }
  .main-menu{
    &__dropdown{
      width: 590px;
    }
    &--active{
      .main-menu__toggle:after{
          top: 24px;
          left: 25px;
      }
    }
    &__title,
    &__item,
    &__index{
      font-size: 20px;
      line-height: 26px;
      padding-left: 100px;
      &--active{
        &:before{
          left:68px;
        }
      }
    }
    &__item{
      line-height: 30px;
      padding: 2px 0 2px 100px;
    }
    &__index{
      margin:17px 0 30px;
    }
    &__title{
      margin: 12px 0 5px;
    }
    &__h{
      padding-left: 98px;
    }
  }
  //kids
  .content__left{
    .count{
      overflow: inherit;
    }
  }
  .slider{
    &__text{
      &.swiper-container{
        //min-height: calc(100% - 100px);
        //max-height:100%;
      }
      &-item{
        &.swiper-slide{
          height:100%;
          //justify-content: flex-start;
        }
      }
    }
  }
  //video
  .video{
    &__item{
      padding: 0 32px 0 91px;
    }
    &__title{
      font-size: 48px;
      line-height: 58px;
    }
  }
  //advice
  .advice{
    &__title{
      font-size:30px;
      line-height: 36px;
    }
    &__text{
      font-size: 18px;
      line-height: 22px;
    }
    &__icon{
      width: 175px;
      height: 175px;
    }
    &__right{
      min-width: 214px;
      width: 100%;
      //margin-left: 160px;
      align-self: center;
    }
    &__item{
      padding:60px 70px;
      //min-height: 440px;
      position: relative;
    }
    &__link{
      position: absolute;
      bottom:42px;
    }
  }
  //advice_in
  .advice-in{
    &__title{
      margin-top:0;
      font-size: 48px;
      line-height: 58px;
    }
    &__head{
      padding:88px 70px;
      position: relative;
      &-content{
        margin-left: 155px;
      }
    }
    &__avatar{
      width: 175px;
      height: 175px;
    }
    &__social{
      margin-bottom:0;
    }
    &__back{
      position: absolute;
      top:25px;
      right:56px;
      &:before{
        border-top: 5px solid transparent;
        border-right: 8px solid #fff;
        border-bottom: 5px solid transparent;
        left: -34px;
      }
    }
    &__content{
      margin-left: 400px;
    }
  }
  //partners

  .partners{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-right: 190px;
    &__wrap{
      flex-direction: row;
      justify-content: space-between;
      height: 100%;
      align-items: flex-end;
    }
    &__develop{
      margin-top:0;
    }
    &__content{
      margin-left:-84px;
    }
    &__item{
        margin-left: 84px;
    }
  }
  .content-partners{
    .content-left{
      flex:0 0 390px;
    }
  }
  //context
  .slider{
    &__content-context{
      .slider{
        &__item{
          padding:0 0 14%;
          &-head{
            width:100%;
          }
          &-avatar{
            max-width:440px;
            width:100%;
            height:440px;
            margin-left:0;
          }
          &-index{
            margin-bottom: 100px;
          }
          &-content{
          }
          &-right{
            display: flex;
            //padding:136px 0 0 0;
            padding:20px 0 0 0;
            margin-right: 80px;
          }
          &-left{
            //padding:140px 127px 0 63px;
            padding:20px 127px 0 63px;
          }
        }

      }
    }
  }
  //test_grow
  .test-grown{
    &__box{
      padding:80px;
    }
    &__item{
      padding:10px;
    }
    &__img{
      text-align: center;
      img{
        max-height: 560px;
      }
    }
  }
  //choice
  .section-choice{
    .choice{
      &__box{
        .count{
          right: 90px;
          top: 90px;
          &~.count{
            bottom: 90px;
            left: 90px;
            right:initial;
            top:initial;
          }
        }
      }

    }
  }
  //add materials
  .materials{
    &__item{
      width: 280px;
    }
    &__title{
      &~&{
        margin-top: 40px;
      }
    }
  }
}
