

/*::-webkit-input-placeholder {}*/

/*::-moz-placeholder {}*/ /* firefox 19+ */

/*:-moz-placeholder {}*/ /* firefox 18- */

/*:-ms-input-placeholder {}*/ /* ie */

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, menu, nav, section {
  display: block;
}

/** * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/) * http://cssreset.com */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre, a,
abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center,
dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, figure, figcaption, footer, header, menu, nav, output, ruby, section, summary, time, mark,
audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  font: inherit;
  font-size: 100%;
  vertical-align: top;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: none;
}

b, u, i, strong, em {
  vertical-align: baseline;
}
strong,b{
  font-weight:800;
}
html,
body {
  height: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  height: 100%;
  width: 100%;
}

*,
*:before,
*:after {
  outline: none;
  vertical-align: top;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

table {
  border-collapse: separate;
  border-spacing: 0;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

a {
  transition: opacity .15s linear, color .15s linear, background-color .15s linear, border-color .15s linear;
}

a:link {
}

a:visited {
}

a:active {
}

a:hover {
}

a:focus {
}
.text-white{
  p{
    color: #fff;
    line-height: 20px;
    font-size: 18px;
  }
}
p {
  font:normal 16px/26px 'Proxima Nova';
  width: 100%;
}

p + p {
  margin-top: 26px;
}

h1 {
  font:800 70px/84px 'Proxima Nova';
  color: #fff;
  display: inline-flex;
  margin-top: 26px;
  &:after{
    content:'';
    height:10px;
    width:100px;
    background: #fff;
    display: flex;
    align-self: center;
    margin-left: 84px;
  }
}

h2 {
  font:800 38px/46px 'Proxima Nova';
  margin-bottom: 16px;
}

h3 {
  font:800 36px/44px 'Proxima Nova';
  margin-bottom: 16px;
}

h4 {
}

ol, ul {
  margin-top: 26px;
}
ul{

}
li {
  font:normal 18px/22px 'Proxima Nova';
  color: #000;
  padding-left: 18px;
  margin-left: 18px;
}
ol{
  li{
    font:normal 18px/26px 'Proxima Nova';
    color: #000;
    padding-left: 18px;
    margin-left: 17px;
  }
  &.bold-list{
    counter-reset: myCounter;
    li{
      padding-left: 13px;
      list-style: none;
      margin-bottom: 17px;
      margin-left: 17px;
      position: relative;
      &:before{
        counter-increment: myCounter;
        content:counter(myCounter);
        font:800 18px/22px 'Proxima Nova';
        color: #000;
        position: absolute;
        left:-13px;
      }
    }
  }
}
.justify {
  text-align: justify;
  margin-bottom: 0;
  line-height: 0;
}

.justify > * {
  display: inline-block;
}

.justify:after {
  height: 20px;
  display: inline-block;
  width: 100%;
  content: '';
}

.align-middle-box {
  font-size: 0;
}

.align-middle-box > *,
.align-middle-box:before{
  display: inline-block;
  vertical-align: middle;
}

.align-middle-box:before{
  height: 100%;
  content: '';}

.group:after,
.input-line:after,
.row:after {
  content: "";
  display: table;
  clear: both;
}
::-webkit-input-placeholder {color:#000;}
::-moz-placeholder          {color:#000;}/* Firefox 19+ */
:-moz-placeholder           {color:#000;}/* Firefox 18- */
:-ms-input-placeholder      {color:#000;}

