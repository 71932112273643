//layout
.interactive{
  &__controls{
    position: absolute;
    margin: auto 0 -51px;
    z-index: 2;
    bottom: 16%;
    right:0;
    visibility: visible;
    transition: all .25s;
    &--hide {
      opacity: 0;
      visibility: hidden;
    }
  }
  &__up,
  &__down{
    width: 60px;
    height: 50px;
    background: #000;
    position: relative;
    cursor: pointer;
    text-align: center;
    transition: all .25s;
    &:after{
      content: '';
      width: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 8px solid #fff;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -5px;
      transition: all .25s;
    }
  }
  &__up{
    &:hover{
      &:after{
        top: calc(50% - 5px);
      }
    }
    &--disabled{
      background: #F0F0F0;
      &:hover:after{
        top: 50%;
      }
    }
  }
  &__down{
    padding-top: 9px;
    &:after{
      border-bottom: none;
      border-top: 8px solid #fff;
      bottom: 12px;
      margin:0 0 0 -5px;
      top:inherit;
    }
    &:hover{
      &:after{
        bottom: 7px;
      }
    }
    &--disabled{
      background: #F0F0F0;
      &:hover:after{
        bottom: 12px;
      }
    }
  }
  &__text{
    color: #fff;
    font: 800 12px/14px 'Proxima Nova';
  }
}
.content-link{
  &__arrow{
    height:30px;
    width: 2px;
    background: #000;
    position: relative;
    margin-right: 14px;
    transition: all .25s;
    &:after{
      position: absolute;
      bottom:-8px;
      left:-4px;
      content:'';
      width:0;
      height:0;
      border-top:8px solid #000;
      border-left:5px solid transparent;
      border-right:5px solid transparent;
    }
  }
  &__next{
    text-decoration: none;
    font: 800 14px/26px 'Proxima Nova';
    color: #000;
    display: flex;
    margin:auto 0 0;
    padding: 20px 0 0;
    &:hover{
      .content-link{
        &__arrow{
          height: 25px;
          margin-top:5px;
        }
      }
    }
  }
  &__text{
    margin-top: 4px;
  }
}
.url-pager{
  position: absolute;
  top:50%;
  left:-37px;
  transform: translateY(-50%);
  &__romb{
    width: 13px;
    height: 13px;
    background: rgba(0,0,0,1);
    transform: rotate(45deg);
    display: block;
    transition: all .5s;
    cursor: pointer;
  }
  &__item{
    margin:3px;
    position: relative;
    text-align: center;
    display: block;
    &:last-child{
      &:before,&:after{
        display: none;
      }
    }
    &:after{
      content:'';
      display: block;
      margin:2px auto 0;
      height: 28px;
      width: 1px;
      background: rgba(0,0,0,.2);
    }
    &:before{
      content:'';
      display: inline-block;
      margin:2px 0 0 -1px;
      height: 28px;
      width: 1px;
      background: rgba(0,0,0,1);
      position: absolute;
      top:14px;
      transition: all .5s;
      left:50%;
    }
    &--active{
      &:before{
        height: 0px;
      }
      .url-pager{
        &__romb{
          background: rgba(0,0,0,1);
        }
      }
      &~.url-pager__item{
        &:before{
          height: 0px;
        }
        .url-pager{
          &__romb{
            background: rgba(0,0,0,.2);
          }
        }
      }
    }
  }
}
.logo{
  width: 68px;
  height: 16px;
  position: absolute;
  //bottom:5vh;
  bottom:5vh;
  margin-bottom:-9px;
  left:50px;
  z-index: 1;
  svg{
    transition-delay: .7s;
    transition: all .25s;
    fill: #fff;
  }
}
.saw ~ .layout__controls{
  .logo {
    svg{
      transition-delay: .7s;
      fill:#fff;
    }
    &--active{
      svg{
        fill:#000;
      }
    }
  }
}
.saw.saw--static ~ .layout__controls{
  .logo {
    svg{
      transition-delay: .7s;
      fill:#000;
    }
    &--active{
      svg{
        fill:#fff;
      }
    }
  }
}
.saw__top ~ .layout__controls{
  .logo {
    svg{
      transition-delay: .7s;
      fill:#000;
    }
    &--active{
      svg{
        fill:#fff;
      }
    }
  }
}
.saw.saw--static ~ .logo {
  svg{
    transition-delay: .7s;
    fill:#fff;
  }
  &--active{
    svg{
      fill:#fff;
    }
  }
}
.saw__top.saw--static ~ .logo {
  svg{
    transition-delay: .7s;
    fill:#000;
  }
  &--active{
    svg{
      fill:#000;
    }
  }
}

.link-next,
.link-prev{
  display: none;
}
.container{
  width:100%;
  margin:0 auto;
  padding:0 50px;
}
.wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  min-height: 100%;
  //padding: 40px 0;
  background: #e9e5ff;
  position: relative;
  //padding: 26px 0;
  padding:10vh 0;
  overflow: hidden;
  &__grown{
    background: #e1ebf8;
  }
}
.count{
  display: flex;
  width:126px;
  flex-wrap: wrap;
  overflow: hidden;
  &__item{
    width: 0;
    height: 0;
    border-top: 37px solid transparent;
    border-left: 42px solid #fff;;
  }
  &__box{
    display: flex;
    overflow: hidden;
  }
  &__rotate{
    transform: rotate(180deg);
    margin-left: auto;
  }
  &__black{
    .count{
      &__item{
        border-left: 42px solid #000;
      }
    }
  }
}
.help{
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 432px;
  position: relative;
  &__header{
    min-height: 116px;
  }
  &__left,
  &__right{
    width: 50%;
    text-align: left;
    padding:40px 40px 50px;
  }
  &__left{
    padding-right: 40px;
    border-right: 1px solid #e8e8e8;
  }
  &__right{
    padding-left: 40px;
  }
  &__title{
    color: #000;
    font:800 48px/58px 'Proxima Nova';
  }
  &__text{
    margin-top: 6px;
    color: #000;
    font:normal 14px/26px 'Proxima Nova';
  }
  &__contacts{
    margin-top: 18px;
    &~&{
      margin-top: 14px;
    }
    &-number{
      color: #000;
      font:800 28px/34px 'Proxima Nova';
    }
    &-text{
      color: #757575;
      font:normal 14px/26px 'Proxima Nova';
    }
  }
}
.help-page {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  &__header{
    margin-bottom: 6px;
  }
  &__left,
  &__right{
    padding-top: 100px;
    display: flex;
    flex-direction: column;

  }
  &__left{
    margin-left: auto;
    padding-right: 190px;
    border-right: 1px solid #e8e8e8;
  }
  &__right{
    max-width: 384px;
  }
  &__title{
    margin-bottom: 10px;
    color: #000;
    font:700 48px/58px 'Proxima Nova';
  }
  &__text{
    max-width: 370px;
    min-height: 78px;
    margin-bottom: 12px;
    color: #000;
    font:normal 14px/26px 'Proxima Nova';
  }
  &__contacts{
    &-number{
      color: #000;
      font:700 28px/34px 'Proxima Nova';
    }
    &-text{
      margin-bottom: 15px;
      font:normal 14px/26px 'Proxima Nova';
      color: #757575;
    }
  }
}
.facebook{
  display: block;
  margin-top: 50px;
  width: 30px;
  height: 30px;
  background: #3b5998;
  border-radius: 50%;
  padding:6px;
  cursor: pointer;
  text-decoration: none;
}
//index
.videoloop{
  position: absolute;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  transform: translate3D(-50%, -50%, 0);
  video{
    position: fixed;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
  }
}
.main-menu{
  &__top{
    display: none;
  }
  &__buttom{
    display: none;
  }
  &--active{
    z-index: 3;
    .main-menu{
      &__toggle{
        z-index: 4;
        &-item{
          background: #fff;
          &:after{
            top:0;
            left: 3px;
            transform: rotate(45deg);
            width: 20px;
          }
          &:before{
            top:0;
            left: 3px;
            transform: rotate(-45deg);
            width: 20px;
          }
        }
      }
      &__dropdown{
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &__toggle{
    cursor: pointer;
    width:50px;
    height:50px;
    background: #fff;
    position: absolute;
    left:-2px;
    top:-2px;
    display: flex;
    z-index: 1;
    transition: all .25s;
    padding:15px 12px;
    &-item{
      width:26px;
      height: 4px;
      background: #000;
      position: absolute;
      //top:50%;
      top: 25px;
      margin-top:-2px;
      transition: all .25s;
      &:after,
      &:before{
        content:'';
        width:26px;
        height: 4px;
        background: #000;
        position: absolute;
        left:0;
        top:8px;
        transition: all .25s;
      }
      &:before{
        top: -8px;
      }
    }
    &-box{
      width: 50px;
      height: 50px;
      position: absolute;
      left: -4px;
      top: -4px;
      z-index: 3;
      &:hover{
        .main-menu__toggle{
          left: 4px;
          transform: translateY(4px);
        }
      }
    }
    &-shadow{
      width:50px;
      height:50px;
      background: #000;
      position: absolute;
      right:-4px;
      top:4px;
    }
  }
  &__dropdown{
    width:478px;
    height:100%;
    background: #fff;
    position: absolute;
    left:0;
    top:0;
    opacity:0;
    visibility: hidden;
    transition: all .25s;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__content{
    &-item~&-item{
      margin-top: 11px;
    }
    &-item:first-child{
      margin-top:12px;
    }
  }
  &__head{
    display: none;
  }
  &__title,
  &__item,
  &__index{
    font:800 16px/26px 'Proxima Nova';
    color: #000;
    text-decoration: none;
    display: block;
    padding-left:90px;
    position: relative;
  }
  &__index{
    margin:12px 0 21px;
  }
  &__h {
    font: 800 38px/26px "Proxima Nova";
    padding-left: 90px;
    margin: 25px 0;
    color: #dcdcdc;
  }
  &__title{
    margin-bottom: 7px;
    &-link{
      font-weight: normal;
    }
    &--active {
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-left: 8px solid #000;
        border-bottom: 6px solid transparent;
        top: 50%;
        left: 68px;
        margin-top: -8px;
        transition: all .25s;
      }
    }
    &-link{
      &:after{
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-left: 8px solid #000;
        border-bottom: 6px solid transparent;
        top: 50%;
        left: 68px;
        margin-top: -8px;
        opacity: 0;
        transition: all .25s;
      }
      &:hover:after{
        opacity: 1;
      }
    }
  }
  &__item{
    font-weight:normal;
    line-height: 18px;
    padding:6px 0 6px 90px;
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-top: 6px solid transparent;
      border-left: 8px solid #000;
      border-bottom: 6px solid transparent;
      top: 50%;
      left: 68px;
      margin-top: -8px;
      transition: all .25s;
      opacity: 0;
    }
    &--active:after{
      opacity: 1;
    }
    &:hover:after {
      opacity: 1;
    }
    &-info{
      display: none;
    }
    &-standalone {
      padding-top: 6px;
    }
  }
  .facebook-icon{
    fill:#000;
  }
}
.social{
  margin:25px 0 0 89px;
  &__item{
    width: 10px;
    height:20px;
  }
}
.content-border{
  border: solid 2px #ffffff;
  padding:48px;
  position: relative;
  min-height:80vh;
  height:580px;
  &__box{
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
  }
  &__top,
  &__middle,
  &__bottom{
    display: flex;
    justify-content: space-between;
    transition: all .25s;
  }
  &__top{
    align-items: flex-start;
  }
  &__middle{
    margin-top:24px;
  }
  &__bottom{
    margin-top: 65px;
    align-items: flex-end;
  }
  &__left,
  &__right{
    width: 380px;
    transition: all .25s;
  }
}
.main-menu--active ~ .content-border__box {
  transition: all .25s;
  .content-border{
    &__middle,
    &__bottom{
      opacity:0;
      visibility: hidden;
    }
    &__top{
      .content-border__left{
        opacity:0;
        visibility: hidden;
      }
    }
  }

}
//kids

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  height: inherit;
  img{
    min-height: 100%;
  }
}
.swiper-container {
  width: 100%;
  height: 500px;
  //max-height:80vh;
  min-height: 500px;
}

.slider{
  position: relative;
  overflow: hidden;
  height: 500px;
  min-height:80vh;
  //&:after{
  //  content:'';
  //  display: block;
  //  width:100%;
  //  height:16%;
  //  position: absolute;
  //  left:0;
  //  bottom: 0px;
  //  background-color: #b3afd1;
  //  opacity:.8;
  //  z-index: 1;
  //}
  &-bottom-overflow{
    display: block;
    width:100%;
    height:16%;
    position: absolute;
    left:0;
    bottom: 0px;
    background-color: #b3afd1;
    opacity:.8;
    z-index: 2;
  }
  &-context{
    height:100%;
    .swiper-container.slider__content-context{
      min-height: 80vh;
      height: 500px;
      max-height:inherit;
    }
    &:after{
      display: none;
    }
  }
  &__img{
    width: 100%;
    height: 100%;
    background-size:cover ;
    background-position: center center;
  }
  &__content{
    overflow: initial;
    min-height: 420px;
    max-height:inherit;
    .swiper-wrapper{
      //height: 100% !important;
    }
  }
  .swiper-container{
    //max-height: 80vh;
    height: 100%;
  }
  .slider__content-context{
    //min-height:100%;
  }
  &__item{
    &-content{
      display: none;
    }
  }
  &__pager{
    display: flex;
    align-items: baseline;
    margin-top: 90px;
    &-active,
    &-total{
      font:800 24px/30px 'Proxima Nova';
      color: #000;
    }
    &-total{
      font-size:20px;
      line-height:26px;
      &:before{
        content:'/';
        color: #000;
        margin:0 5px;
      }
    }
  }
  &__controls{
    position: absolute;
    margin: auto 0 -50px;
    z-index: 2;
    bottom:16%;
    right:0;
    &-level{
      height:16%;
      width:100%;
    }
  }
  &__up,
  &__down{
    width:60px;
    height:50px;
    background: #000;
    position: relative;
    cursor: pointer;
    text-align: center;
    transition: all .25s;
  }
  &__up{
    &:hover{
      &:after{
        top:calc(50% - 5px);
      }
    }
  }
  &__down{
    padding-top: 9px;
    &:hover{
      &:after{
        bottom:7px;
      }
    }
    &-text{
      color: #fff;
      font:800 12px/14px 'Proxima Nova';
    }
  }
  &__up:after{
    content:'';
    width:0;
    border-left:5px solid transparent;
    border-right:5px solid transparent;
    border-bottom:8px solid #fff;
    position: absolute;
    left:50%;
    top:50%;
    margin:-5px;
    transition: all .25s;
  }
  &__down:after{
    content:'';
    width:0;
    border-left:5px solid transparent;
    border-right:5px solid transparent;
    border-top:8px solid #fff;
    position: absolute;
    left:50%;
    bottom:12px;
    margin-left:-5px;
    transition: all .25s;
  }
  &__text{
    &-item.swiper-slide{
      //height:234px;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      text-align: left;
      height: initial;

    }
    &:after{
      display: none;
    }
  }
  &__text.swiper-container{
      //height:234px;
  }
  &__item.swiper-slide{
    height: 100%;
    overflow: hidden;
  }
  &__text{
    height: auto;
    min-height: auto;
    .swiper-wrapper{
      flex-direction: column;
    }
  }
}

.swiper-button-disabled {
  &.slider__up {
    background: #F0F0F0;
    &:hover:after {
      top: 50%;
    }
  }
  &.slider__down {
    background: #F0F0F0;
    &:hover:after {
      bottom: 12px;
    }
  }
}
.content{
  background: #fff;
  position: relative;
  display: flex;
  //min-height:500px;
  //margin: 24px 0 24px;
  min-height: 80vh;
  height:100%;
  .main-menu__toggle{
    left:0;
    top: 0;
  }
  &__left{
    flex: 0 0 478px;
    padding:76px 120px 50px 50px;
    display: flex;
    min-height:500px;
    flex-direction: column;
    position: relative;
    &-controls{
      width: 60px;
      flex:1 0 60px;
      display: flex;
      flex-direction: column;
      margin-left: 50px;
    }
    &-media{
      padding:0;
      flex-direction: row;
      background: #fff;
    }
    &-box{
      padding: 76px 0 50px 50px;
      display: flex;
      flex-direction: column;
    }
    &-help {
      flex:1;
      padding: 76px 0px 50px 50px;
    }
  }
  &__right{
    width: 100%;
    min-height:500px;
    //add
    height: initial;
    &-help {
      width: 50%;
      padding: 70px 50px 50px 100px;
    }
  }
  .count{
    margin-top: auto;
  }
  &__head{
    display: flex;
    align-items: center;
    position: relative;
    width: 90%;
    //height: 100%;
    &-left{
      &>h3:first-child{
        margin-bottom:0;
      }
    }
    .count{
      display: none;
    }
  }
}
.description-text{
  font:normal 15px/20px 'Proxima Nova';
  color: #cecece;
  margin:0 0 16px 20px;
  position: relative;;
}
//video_in
.video-player{
  position: relative;
  margin:0 auto;
  width: 100%;
  height: 100%;
  background: #000;
  &__video{
    width: 100%;
    height: 100%;
    video{
      width: 100%;
      height: 100%;
    }
  }
  &__back{
    position: absolute;
  }
  &__controls{
    position: absolute;
    bottom:10%;
    left:50%;
    transform: translateX(-50%);
    display: flex;
    width:100%;
    justify-content: space-between;
    padding:0 100px 0 50px;
    align-items: center;
    transition: all .25s;
    max-width: 1200px;
    &--hidden{
      visibility: hidden;
      opacity:0;
    }
  }
  &__play{
    width: 110px;
    height: 110px;
    cursor: pointer;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    position: relative;
    transition: all .25s;
    &:after{
      content:'';
      position: absolute;
      top:50%;
      left:50%;
      margin:-55px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      width:110px;
      height:110px;
      background: rgba(255,255,255,.19);
      transition: all .25s;
    }
    &:hover{
      &:after{
        width:80px;
        height:80px;
        margin:-40px;
      }
    }
    &--pause{
      .video-player__play-icon{
        &:after,
        &:before{
          width:4px;
          height:20px;
          background: #000;
          left:34px;
          top:50%;
          margin:-10px 0 0 0px;
          border: none;
        }
        &:before{
          margin:-10px 0 0 8px;
        }
      }
    }
    &-icon{
      width: 80px;
      height: 80px;
      position: absolute;
      left:50%;
      top:50%;
      margin:-40px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background: #fff;
      &:after,
      &:before{
        content:'';
        transition: all .25s;
        display: block;
        position: absolute;
        border-left:20px solid #000;
        border-top:10px solid transparent;
        border-bottom:10px solid transparent;
        left:50%;
        top:50%;
        margin:-10px 0 0 -8px;
      }
    }
  }
  &__timeline{
    display: flex;
    align-items: center;
    max-width: 770px;
    width: 100%;
    flex:1;
    margin:0 30px;
  }
  &__timeCode{
    color: #fff;
    font:normal 18px/26px 'Proxima Nova';
    width: 50px;
    margin-right: 20px;
  }
  &__progress{
    width:100%;
    margin:0;
    cursor: pointer;
    height: 20px;
    z-index: 2;
    opacity:0;
    position: relative;
    background: transparent;
    &-box{
      position: relative;
      align-items: center;
      display: flex;
      max-width:700px;
      height: 3px;
      flex:1;
      background: rgba(255,255,255,.3);
      cursor: pointer;
      color: #000;
      z-index: 1;
      &:hover{
        .video-player__progress-play{
          &:after{
            width: 26px;
            height:26px;
            top:-11px;
            right:-17px;
          }
        }
      }
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background: #ffffff;
      cursor: pointer;
      opacity:1;
      transition: all .15s;
      visibility: hidden;
    }
    &::-ms-thumb{
      width: 20px;
      height: 20px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background: #ffffff;
      cursor: pointer;
      opacity:1;
      border: none;
      transition: all .15s;
      visibility: hidden;
    }
    &::-moz-range-thumb{
      width: 20px;
      height: 20px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background: #ffffff;
      cursor: pointer;
      border: none;
      transition: all .15s;
      visibility: hidden;
    }
    &:hover{
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 26px;
        height: 26px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background: #ffffff;
        cursor: pointer;
        opacity:1;
      }
      &::-ms-thumb{
        width: 26px;
        height: 26px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background: #ffffff;
        cursor: pointer;
        opacity:1;
        border: none;
      }
      &::-moz-range-thumb{
        width: 26px;
        height: 26px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background: #ffffff;
        cursor: pointer;
        border: none;
      }
    }
    &-play{
      width:0%;
      height:3px;
      background: #fff;
      transition: all .25s;
      position: absolute;
      top:0;
      left:0;
      z-index: 1;
      &:after{
        content:'';
        right:-14px;
        width:20px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        height:20px;
        position: absolute;
        top:-9px;
        background: #fff;
        display: block;
        transition: all .15s;
      }
    }
  }
  &__volume{
    z-index: 2;
    margin:0;
    width:100%;
    height: 20px;
    opacity: 1;
    background: transparent;
    position: relative;
    cursor: pointer;
    padding:0;
    top:-9px;
    &:focus{
      outline: none;
    }
    &::-ms-track{
      width:100%;
      height: 20px;
      opacity: 1;
      background: transparent;
      position: relative;
      cursor: pointer;
      padding:0;
      top:-9px;
      border: none;
      color: transparent;
    }
    &::-ms-fill-lower,
    &::-ms-fill-upper{
      background: transparent;
      opacity: 0;
    }
    &::-moz-range-track{
      opacity:0;
    }
    &-box{
      display: flex;
      align-items: center;
      position: relative;
    }
    &-status{
      position: relative;
      display: flex;
      width:80px;
      height:3px;
      background: rgba(255,255,255,.3);
      cursor: pointer;
    }
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background: #ffffff;
      cursor: pointer;
      opacity:1;
      transition: all .15s;
    }
    &::-ms-thumb{
      width: 20px;
      height: 20px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background: #ffffff;
      cursor: pointer;
      opacity:1;
      border: none;
      transition: all .15s;
    }
    &::-moz-range-thumb{
      width: 20px;
      height: 20px;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      background: #ffffff;
      cursor: pointer;
      border: none;
      transition: all .15s;
    }
    &:hover{
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 26px;
        height: 26px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background: #ffffff;
        cursor: pointer;
        opacity:1;
      }
      &::-ms-thumb{
        width: 26px;
        height: 26px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background: #ffffff;
        cursor: pointer;
        opacity:1;
        border: none;
      }
      &::-moz-range-thumb{
        width: 26px;
        height: 26px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        background: #ffffff;
        cursor: pointer;
        border: none;
      }
    }
    &-icon{
      width: 20px;
      height: 16px;
      margin-right: 10px;
      transition: all .25s;
      z-index: 1;
      &--mute{
        opacity:0;
      }
      &__mute{
        width: 12px;
        height: 16px;
        position: absolute;
        left: -1px;
        top: 0px;
      }
    }
    &-progress{
      position: absolute;
      left:0;
      top:0;
      height:3px;
      width:100%;
      background: #fff;
      z-index: 1;
    }
  }
}
//video
html:root*.video{
  height: 80vh;
  min-height: 500px;
}
.video{
  min-height: 100%;
  height: 80vh;
  &__item{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:0 28px 0 28px;
    width:100%;
    background: #f4f4f4;
    cursor: pointer;
    transition: all .25s;
    height:33.333333333333333%;
    &:hover{
      background: #e0deeb;
      .video{
        &__img{
          //width: 108px;
          //height: 108px;
          //height: 70%;
          background-color: #655c9f;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;
          background-blend-mode: screen;
          //margin-right: 6px;
          img{
            //height: 108px;
            //height: 70%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            filter: grayscale(100%);
          }
          &:after{
            opacity:.5;
          }
        }
      }
    }
    &-blue{
      &:hover{
        background-color: #d6e8fe;
        .video{
          &__img{
            &:after{
              background-color: #dcecff;
              opacity:.5;
            }
          }
        }
      }
    }
    &:hover{
      .video{
        &__img-box{
          //padding:1%;
          padding: 0.5vw;
        }
      }
    }
  }
  &__title{
    font:800 36px/44px 'Proxima Nova';
    display: inline-flex;
    height:100%;
    align-items: center;
  }
  &__img{
    transition: all .25s;
    width: 100%;
    height: 100%;
    background-size: cover;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    &-box{
      height:80%;
      width:120px;
      display: flex;
      transition: all .25s;
      flex-shrink: 0;
    }
    img{
      //height: 120px;
      height: 100%;
      transition: all .25s;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
    }
    &:after{
      content:'';
      display: block;
      width:100%;
      height:100%;
      background: #655c9f;
      opacity:0;
      position: absolute;
      top:0;
      left:0;
      z-index: 1;
      transition: all .25s;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
    }
  }
  &__play{
    width: 60px;
    height:60px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    top:50%;
    left:50%;
    margin:-30px 0 0 -30px;
    z-index: 2;
    &:before{
      content:'';
      display: block;
      border-top:8px solid transparent;
      border-left:16px solid #000;
      border-bottom:8px solid transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -8px 0 0 -6px;
    }
  }
}
//advice
  .advice{
    width:100%;
    display: flex;
    flex-wrap:wrap;
    overflow: hidden;
    max-height: 80vh;
    min-height: 500px;
    min-height:560px;
    &__icon{
      width: 115px;
      height: 115px;
      align-self: center;
      path{
        fill:#fff;
      }
    }
    &__link{
      font:800 18px/22px 'Proxima Nova';
      color: #fff;
      margin-top: 18px;
      display: inline-block;
      text-decoration: none;
      transition: all .25s;
      opacity:0;
      &:after{
        content:'';
        display: block;
        width:100%;
        height:1px;
        margin-top:-4px;
        background: rgba(255,2255,255,1);
      }
    }
    &__item{
      //height: 50%;
      //height: 40vh;
      width: 50%;
      display: flex;
      background: #000;
      color: #fff;
      padding:20px 30px 20px 48px;
      justify-content: space-between;
      cursor: pointer;
      text-decoration: none;
      min-height:250px;
      height: 40vh;
      &:nth-child(4n+2){
        background: #fff;
        color: #000;
        .advice__icon{
          path{
            fill: #000;
          }
        }
        .advice__link{
          color: #000;
          &:after{
            background: rgba(0,0,0,1);
          }
        }
      }
      &:nth-child(4n+3){
        background: #fff;
        color: #000;
        .advice__icon{
          path{
            fill: #000;
          }
        }
        .advice__link{
          color: #000;
          &:after{
            background: rgba(0,0,0,1);
          }
        }
      }
      &:hover{
        .advice__link{
          opacity:1;
        }
      }
    }
    &__white{
      background: #fff;
      color: #000;
      .advice__link{
        color: #000;
        &:after{
          background: rgba(0,0,0,1);
        }
      }
    }

    &__left{
      display: flex;
      align-items: center;
    }
    &__right{
      //margin-top: 24px;
      //width:214px;
      width: 64%;
      align-self: center;
    }
    &__title{
      font:800 22px/26px 'Proxima Nova';
      display: block;
    }
    &__text{
      margin-top: 11px;
      font:normal 14px/17px 'Proxima Nova';
      display: block;
    }
}

//advice_in
.advice-in{
  width: 100%;
  position: relative;
  height: 500px;
  min-height: 80vh;
  .mCSB_container{
    display: block;
  }
    &__head{
      display: flex;
      background: #000;
      justify-content: space-between;
      padding:24px 36px 24px 48px;
      &-content{
        margin-left:58px;
      }
    }
    &__avatar{
      width:115px;
      height:115px;
      svg{
        fill:#fff;
      }
    }
    &__title{
      color: #fff;
      margin-top: 57px;
      font:800 36px/44px 'Proxima Nova';
    }
    &__left{
      display: flex;
      align-items: center;
    }
    &__content{
      margin:0 0 0 220px;
      padding:40px 0 60px;
      max-width:600px;
      width:100%;
      .mCSB_container{
        display: block;
      }
      p{
        font:normal 18px/22px 'Proxima Nova';
      }
    }
    &__back{
      font:800 18px/22px 'Proxima Nova';
      color: #fff;
      text-decoration: none;
      position: relative;
      &:before{
        content:'';
        display: block;
        border-top:4px solid transparent;
        border-right:6px solid #fff;
        border-bottom:4px solid transparent;
        position: absolute;
        left:-16px;
        top:50%;
        margin-top:-4px;
      }
    }
    &__social{
      margin: 40px 0 36px;
      #facebook svg{
        width: 12px;
        height: 25px;
      }
      &-item{
        cursor: pointer;
      }
    }
  &__controls{
    padding: 0px 0 30px;
    position: absolute;
    width: 100%;
    bottom:0;
    z-index: 1;
  }
  &__prev,
  &__next {
    float: left;
    padding: 5px 0;
    cursor: pointer;
    margin-left: 40px;
    &-arrow {
      width: 42px;
      height: 2px;
      background: #000;
      position: relative;
      display: block;
      transition: all .25s;
      &:after {
        content: '';
        display: block;
        border-top: 5px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 8px solid #000;
        position: absolute;
        top: -4px;
        left: -8px;
      }
    }
    &:hover{
      .advice-in{
        &__prev,
        &__next{
          &-arrow{
            width: 37px;
          }
        }
      }
    }
  }
  &__next {
    float: right;
    margin: 0 40px 0 0;
    &-arrow {
      &:after {
        border-right: none;
        border-left: 8px solid #000;
        left: inherit;
        right: -8px;
      }
    }
  }
}
.count-share{
  font:normal 18px/26px 'Proxima Nova';
  color: #fff;
  display: inline-block;
  margin-left: 20px;
}
//test
html:root*.test__box {
  height: 80vh;
  min-height: 500px;
}
.test{
  background: #f4f4f4;
  //padding:0 15px 0;
  display: flex;
  overflow-y: hidden;
  flex-wrap: wrap;
  justify-content: center;
  //min-height: 80vh;
  //height: 500px;
  min-height: 100%;
  height:80vh;
  &__add{
    display: none;
  }
  &__item{
    width:200px;
    height: 140px;
    margin-top:15px;
    position: relative;
    cursor: pointer;
    img{
      height: 100%;
    }
    &--active{
      .test__answer{
        visibility: visible;
        opacity:1;
      }
    }
    &:hover{
      .test{
        &__copy{
          visibility: visible;
          opacity: .5;
        }
      }
    }
  }
  &__copy{
    visibility: hidden;
    opacity: 0;
    transition: all .25s;
    letter-spacing: -0.1px;
    font:normal 10px/12px 'Proxima Nova';
    color: #fff;
    position: absolute;
    bottom: 10px;
    left: 10px;
  }
  &__box{
    display: flex;
    margin-left:-15px;
    flex-wrap: wrap;
    padding-bottom: 15px;
    align-self: flex-start;
    //align-items: center;
    //justify-content: space-between;
    &-aloneBlock{
      .test__box-item:last-child{
        flex-direction: row;
        //justify-content: space-between;
        width:100%;
        .test__item:last-child{
          margin-left: 15px;
        }
      }
    }
    &-item{
      display: flex;
      flex-direction: column;
      margin-left:15px;

      &:nth-child(odd){
        .test__item:last-child{
          height:180px;
        }
      }
      &:nth-child(even){
        .test__item:first-child{
          height:180px;
        }
      }
    }
  }
  &__second{
    flex-wrap: wrap;
    display: flex;
    .test{
      &__item:nth-child(odd){
        height: 180px;
        margin-top: -26px;
      }
    }
  }
  &__answer{
    background: #000;
    position: absolute;
    left:0;
    top:0;
    height: 100%;
    width:100%;
    padding:40px 0 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    visibility: hidden;
    opacity:0;
    transition: all .5s;
    .count{
      margin-top: auto;
      width: initial;
      &__item{
        border-top: 20px solid transparent;
        border-left: 23px solid #fff;
      }
    }
    &-text{
      font:800 22px/27px 'Proxima Nova';
      color: #fff;
      width:170px;
    }
  }
  &__controls{
    display: flex;
    flex-direction: column;
    width: 50px;
    position: absolute;
    bottom:0;
  }
  &__up{

  }
  &__down{

  }
}
//partners

.partners{
  padding: 89px 84px 50px 0px;
  height: 500px;
  min-height: 80vh;
  &__wrap{
    display: flex;
    margin-top: 30px;
  }
  &__develop{
    margin-left: 40px;
  }
  &__content{
    display: flex;
    margin-left:-38px;
  }
  &__item{
    max-width: 160px;
    height:128px;
    align-items: center;
    display: flex;
    margin-left:38px;
    padding: 6px;
    justify-content: center;
    svg{
      width: 100%;
      height: 100%;
    }
  }
  &__text{
    font:normal 14px/26px 'Proxima Nova';
    color: rgba(0,0,0,.3);
    margin-left: 6px;
  }
}
.content-partners{
  .content{
    &__left{
      flex: 0 0 326px;
      width: 326px;
    }
  }
}
//context
.context-bullet{
  font:bold 18px/26px 'Proxima Nova';
  color: #000;
  display: block;
  width:100%;
  height: initial;
  &~.context-bullet{
    margin-top: 20px;
  }
  &.swiper-pagination-bullet{
    border: none;
    position: relative;
    height:inherit;
    display: flex;
    align-items: center;
    &:before{
      content:'';
      display: block;
      border-top:6px solid transparent;
      border-bottom:6px solid transparent;
      border-left:8px solid #000;
      position: absolute;
      left: -20px;
      top: 6px;
      transition: all .25s;
      opacity:0;
    }
    &-active{
      &:before{
        opacity: 1;
      }
    }
  }
}
.slider__paginator-context{
  &-active{
    font: bold 18px/26px 'Proxima Nova';
    color: #000;
    width:100%;
    height: initial;
    padding:12px 0;
    display: none;
  }
}
.context{
  &__next{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 16%;
    position: absolute;
    left: 0;
    //bottom: -1px;
    bottom:0;
    background-color: #cdcdcd;
    opacity: 1;
    z-index: 2;
    padding-left: 16px;
    &-text{
      margin-top: 5px;
      color: #676767;
      font:10px/13px 'Proxima Nova';
      position: absolute;
      top: -49px;
      left: 17px;
    }
    &.swiper-button-disabled {
      .context-bullet {
        opacity: 0;
      }
    }
    .context-bullet{
      //height:initial;
      &--hide{
        opacity:0;
      }
    }
  }
}
.slider{
  &__content{
    &-context{
      .swiper-wrapper{
        display: block;
      }
      .slider__item-content{
        display: flex;
        align-self: flex-start;
        width: 100%;
      }
      .slider{
        &__item{
          background: #f4f4f4;
          padding:0 35px 0 17px;
          justify-content: flex-start;
          p{
            font-size:18px;
            line-height:26px;
          }
          &-left{
            min-width: 98%;
            width:68%;
            text-align: left;
            padding-top: 70px;
          }
          &-head{
            width: 68%;
          }
          &-right{
            padding:27px 0;
          }
          &-index{
            font:bold 18px/26px 'Proxima Nova';
            color: #000;
            display: flex;
            align-items: center;
            margin-bottom: 25px;
            &:after{
              content:'';
              margin-left: 20px;
              display: inline-block;
              width:60%;
              height:7px;
              background: #000;
            }
          }
          &-text{
            margin-top: 6px;
          }
          &-avatar{
            width: 110px;
            height: 110px;
            margin-left: -78px;
          }
        }
      }
    }
  }
}
//test_grown
.test-grown{
  display: flex;
  margin-left:0;
  padding:0;
  background: #f4f4f4;
  position: relative;
  &__animated{
    background: #f4f4f4;
    transition: all 1s;
    width:0;
    position: absolute;
    display: block;
    height:100%;
    right:0;
    bottom:0;
    z-index: 3;
    &--next {
      width:100%;
      left:0;
      right:inherit;
    }
    &--back {
      width: 100%;
      right: 0;
    }
    &--active {
      width: 0;
    }
    &--off {
      width: 100%;
    }
  }
  &__box{
    position: relative;
    padding:30px;
    margin-left:0;
    align-self: flex-start;
  }
  &__item{
    width: 50%;
    padding:5px;
  }
  &__img{
    display: block;
    background: url('../img/bg.png');
    -webkit-background-size: cover;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;
    text-align: center;
    &:hover{
      img{
        transform: scale(1.1,1.1);
      }
    }
    img{
      transition: all .25s;
    }
  }
}
//test_grown-in
.test-grown-in{
  display: flex;
  height:100%;
  position: relative;
  background: #f4f4f4;
  width: 100%;
  &__content{
    min-width: 478px;
  }
  &__right{
    width: 50%;
  }
  &__item{
    display: flex;
  }
  &__left{
    width: 50%;
    background: url('../img/bg.png');
    -webkit-background-size: cover;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    flex:1 1 50%;
  }
  &__right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin:0 44px 0 -50px;
    text-align: left;
    flex:1 0 32%;
  }
  &__index{
    font:800 28px/34px 'Proxima Nova';
    color: #000;
    margin-bottom:16px;
    display: flex;
    align-items: center;
    &-line{
      width: 0;
      height:5px;
      background: #000;
      margin-left: 10px;
      transition: all 1s;
    }
    &--active {
      .test-grown-in__index {
        &-line{
          width: 58%;
        }
      }
    }
  }
  &__title{
    font:800 26px/30px 'Proxima Nova';
    color: #000;
    margin-bottom: 17px;
  }
  &__text{
    font:normal 18px/22px 'Proxima Nova';
    color: #000;
  }
  &__img{
    img{
      max-height: 80vh;
    }
  }
}
.content-grown{
  &__left{
    flex: 0 0 478px;
    padding: 52px 18px 50px 38px;
    background: #f4f4f4;
  }
}
.test-grown-slider{
  min-height: 500px;
  height: 100%;
  &:after{
    display: none;
  }
  &__item{
    display: flex;
    width:100%;
  }
  &__controls{
    position: absolute;
    z-index: 2;
    bottom:30px;
    left:0;
    width: 100%;
  }
  &__prev,
  &__next{
    float: left;
    padding:5px 0;
    cursor: pointer;
    margin-left: 30px;
    &-arrow{
      width: 42px;
      height: 2px;
      background: #000;
      position: relative;
      transition: all .25s;
      &:after{
        content:'';
        display: block;
        border-top:5px solid transparent;
        border-bottom:5px solid transparent;
        border-right:8px solid #000;
        position: absolute;
        top:-4px;
        left:-8px;
      }
    }
    &:hover{
      .test-grown-slider{
        &__prev,
        &__next{
          &-arrow{
            width: 37px;
          }
        }
      }
    }
  }
  &__next{
    float: right;
    margin:0 30px 0 0;
    &-arrow{
      &:after{
        border-right: none;
        border-left:8px solid #000;
        left:inherit;
        right:-8px;
      }
    }
  }
}
//layoutHeight
.content__right{
  position: relative;
}
.test,
.slider,
.test-grown-slider,
.test-grown-in,
.slider-context .swiper-container.slider__content-context{
  min-height:100%;
  height: 80vh;
  position: absolute;
}
.video{
  position: absolute;
  width: 100%;
  height:100%;
}
.test,
.slider{
  width: 100%;
}

//test_boys
.total-text{
  font:800 28px/34px 'Proxima Nova';
  color: #000;
  margin-top: 40px;
  &--small{
    font:normal 16px/26px 'Proxima Nova';
    margin:0 0 135px 0;
  }
}
.section-boys{
  h3{
    font-size: 28px;
    line-height: 34px;
  }
  .content{
    &__left{
      width: 50%;
      padding:85px 64px 40px 50px;
      display: flex;
      flex-direction: column;
      background: #fff;
      height:80vh;
      min-height:500px;
      flex: 0 0 50%;
    }
    &__right{
      width: 50%;
      height: inherit;
      overflow-Y: auto;
      background: #fff;
      border-left:1px solid #E3E3E3;
    }
  }
  .more{
    height: 100%;
    &__item{
      background: #655C9F;
      height:70px;
      width:calc( 100% + 40px);
      margin-left: -20px;
      display: none;
      align-items: center;
      justify-content: center;
      position: relative;
      &:after{
        content:'';
        bottom:-18px;
        position: absolute;
        display: block;
        width: 100%;
        background-image: linear-gradient(to bottom right, #655c9f  50.5%, transparent  50.5%);
        background-repeat: repeat-x, repeat-x;
        background-position: 29px 0, 10px 0;
        background-size: 30px 18px, 20px 20px;
        height: 18px;
        left:0;
      }
      &--open{
        .more{
          &__title{
            &:after,
            &:before{
              position: absolute;
              transform: rotate(-45deg);
              right:-10px;
              top:8px;
            }
            &:before{
              transform: rotate(45deg);
              right: -13px;
            }
          }
        }
      }
    }
    &__box{
      display: flex;
      flex-direction: column;
      height: 100%;
      transition: all 1s;
      overflow: hidden;
      padding-left: 4px;
      .button-box{
        justify-content: flex-start;
      }
      .content-link__next{
        padding-top:0;
        margin:0 30px 0 0;
        align-items: center;
        padding-bottom: 8px;
      }
      h3{
        margin-bottom: 26px;
      }
      &--choice{
        justify-content: center;
        padding-bottom: 46px;
        .content-link__next{
          margin:0;
        }
      }
    }
    &__title{
      font:800 14px/17px 'Proxima Nova';
      color: #ffffff;
      position: relative;
      display: flex;
      align-items: center;
      &:after,
      &:before{
        content: '';
        display: block;
        width:5px;
        height:2px;
        background: #fff;
        -webkit-border-radius: 1px;
        -moz-border-radius: 1px;
        border-radius: 1px;
        position: absolute;
        transform: rotate(45deg);
        right:-10px;
        top:8px;
        transition: all .25s;
      }
      &:before{
        transform: rotate(-45deg);
        right: -13px;
      }
    }
  }
  .overflow-content{
    position: absolute;
    width:50%;
    height:100%;
    background: url('../../img/content/triangles.svg') #fff;
    z-index: 1;
    transition: all 1s;
    left:0;
    top:0;
    &--hide{
      opacity:0;
      visibility: hidden;
    }
  }
  .link-purple {
    color: #655C9F;
    font: 800 28px/34px 'Proxima Nova';
    text-decoration: none;
  }
  .choice{
    &__box{
      background: #DEDCEA;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .count{
        position: absolute;
        right: 42px;
        top: 42px;
        &~.count{
          bottom: 42px;
          left: 42px;
          right:initial;
          top:initial;
        }
      }
    }
    &__button{
      text-align: center;
    }
  }
}
//choice
.section-choice{
  .content{
    &__right{
      border: none;
      height: auto;
    }
  }
  .button{
    &--all-black{
      margin-left: auto;
      margin-right: auto;
      min-width: 312px;
      font-size: 16px;
      &~.button--all-black{
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .choice{
    &__box{
      .count{
        width: 168px;
      }
    }
  }
  .button-box{
    position: absolute;
    bottom:40px;
    left:50px;
  }
}
//test-girls
section{
  height:inherit;
}
.pb_feed {
  margin: -20px 0 0 -1px;
  height: 80vh;
}

//add materials
.materials{
  height: 500px;
  min-height: 80vh;
  &__title{
    font:800 18px/26px 'Proxima Nova';
    color: #000;
    margin-bottom: 20px;
    &~&{
      margin-top: 30px;
    }
  }
  &__wrap{
    padding: 116px 0 98px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  &__box{
    font-size: 0;
    margin:-10px 0 0 -10px;
  }
  &__item{
    width: 258px;
    background: #F4F4F4;
    padding:35px 30px 25px;
    text-align: center;
    min-height: 380px;
    position: relative;
    display: inline-flex;
    flex-direction: column;
    margin:10px 0 0 10px;
    .button{
      &-black{
        min-width: 132px;
        font:800 14px/18px 'Proxima Nova';
        padding: 14px 0;
        margin:auto auto 0;
        background: transparent;
        &:hover{
          .button__text{
            top: -50px;
            opacity: 0;
          }
        }
      }
    }
  }
  &__img{
    width: 115px;
    min-height: 108px;
    margin:0 auto;
    img{
      max-width: 115px;
      max-height: 104px;
    }
  }
  &__text{
    font:800 22px/26px 'Proxima Nova';
    color: #000;
    margin-top: 36px;
  }
  .mCSB_container{
    display: block;
  }
}
.content--materials{
  h3{
    font-size: 48px;
    left: 58px;
  }
}