@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .video__item:hover {
    .video__img:after {
      content: '';
      width: 100%;
      height: 100%;
      background: #655c9f;
      opacity: .5;
    }
  }
  .video__item-blue:hover {
    .video__img:after {
      content: '';
      width: 100%;
      height: 100%;
      background: #d6e8fe;
      opacity: .5;
    }
  }

  .videoloop{
    left:0;
    top:0;
  }
  .content__left-box{
    width: 418px;
    padding-right: 60px;
  }
  .content__left-controls{
    margin:0 ;
  }
  .content__left{
    min-width: 478px;
    flex:0 0;
  }
  .content__right{
    flex:0 1 100%;
  }
  .content-link {
    &__arrow,
    &__text {
      display: block;
    }
  }
  .content{
    height: 80vh;
    min-height: 500px;
    &__head{
      //display: inline-block;
    }
  }
  .advice{
    &__right{
      display: inline-block;
    }
    &__icon{
      display: inline-flex;
      align-items:center;
    }
  }
  .test-grown{
    display: block;
    &__box{
      flex:0 0 100%;
    }
  }
  .content__left{
    position: absolute;
    height: 100%;
    width: 478px;
    left:0;
    &-help {
      position: relative;
      width: auto;
      flex: 0 0 50%;
    }
  }
  .content__right{
    position: absolute;
    height: 100%;
    width: calc(100% - 478px);
    right:0;
    &-help {
      position: relative;
      width: auto;
    }
  }
  @media all and (max-width: 1023px) {
    .content__left{
      position: relative;
      width: 100%;
    }
    .content__right{
      position: relative;
      height: 100%;
      width: 100%;
      right:0;
    }
    .content__left-box {
      width: 100%;
      padding: 0;
    }
    .content{
      height: 100%;
      min-height:100%;
    }
    .content__head .count__black{
      top: 0;
    }
  }
}